define('editor-burgendatenbank/controllers/editor', ['exports', 'ember', 'fetch'], function (exports, _ember, _fetch) {
    exports['default'] = _ember['default'].Controller.extend({
        authmanager: _ember['default'].inject.service('auth-manager'),
        toast: _ember['default'].inject.service(),

        user_isAdmin: false,

        toast: _ember['default'].inject.service(),
        vocabmanager: '',
        downloadmanager: _ember['default'].inject.service('download-manager'),

        queryParams: ['url'],
        url: "",
        loading: true,

        response_xmlfile: '',

        county_abbr: '',
        county_id: '',
        xmlfile: '',

        genCount: 0,
        uploadXML: false,
        relaseXML: false,

        init: function init() {
            this._super();

            if (this.get('authmanager').userIsAdmin()) {
                this.set('user_isAdmin', true);
            }

            this.set('loading', true);
        },

        updateQueryParams: (function () {

            var folder_split = this.url.split("/");
            var foldername = folder_split[folder_split.length - 1];
            var foldername_split = foldername.split("-");

            this.set('county_abbr', foldername_split[0]);
            this.set('county_id', foldername_split[1]);
            this.set('xmlfile', foldername_split[2] + '.xml');

            console.log(this.xmlfile);
            console.log(this.url + '/' + this.xmlfile + '?' + Math.random() * 100);

            ///fileserver/files/Bundesrepublik%20Deutschland/Bayern/Unterfranken/Landkreis%20Haßberge/Altenstein/Altenstein.xml

            this.get('downloadmanager').init(this.url + '/' + this.xmlfile + '?' + Math.random() * 100, this);

            /*if (!this.folder) {
                this.set('response_xmlfile', '');
                this.set('page_title_sync', '');
                this.set('loading', false);
            }*/
        }).observes('url'),

        // =========================================
        // Sharing
        // =========================================
        sync_objref_list: [],
        sync_phaseref_list: [],
        sync_familyref_list: [],
        // =========================================
        // =========================================

        // =========================================
        // =========================================

        genXMLfromComponents: 0,

        header_xml: '',
        object_xml: '',
        chronology_xml: '',
        people_desc_xml: '',
        shortdescs_xml: '',
        currentuse_xml: '',
        literature_xml: '',
        refs_xml: '',
        attachments_xml: '',

        generateXML: function generateXML() {
            this.set('genCount', 0);

            var xml = '<ArchDesc xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xsi:noNamespaceSchemaLocation="ArchDesc1.0.xsd">';
            xml += this.get('header_xml');
            xml += this.get('object_xml');
            xml += this.get('chronology_xml');
            xml += this.get('people_desc_xml');
            xml += this.get('shortdescs_xml');
            xml += this.get('currentuse_xml');
            xml += this.get('literature_xml');
            xml += this.get('refs_xml');
            xml += this.get('attachments_xml');
            xml += '</ArchDesc>';

            this.set('xml_export', xml);

            console.log(this.get('xml_export'));

            if (this.get('uploadXML')) {

                var self = this;
                var formData = new FormData();
                formData.append('path', this.url.replace("/fileserver/files/", ""));
                formData.append('xmlFile', this.xmlfile);
                formData.append('xmlCode', this.get('xml_export'));

                if (this.get('relaseXML')) {
                    formData.append('release', "release");

                    var request = new XMLHttpRequest();
                    request.open("POST", "/fileserver/api/filemanager/updateXML.php");

                    request.onload = function (oEvent) {
                        if (request.status == 200) {
                            console.log(request);
                            console.log("Done!");
                            self.toast.success('Datei erfolgreich gespeichert. Freigabe erteilt.');
                            //self.replaceRoute('dashboard');
                        } else {
                                console.log("Error " + oReq.status);
                            }
                    };

                    request.send(formData);
                } else {
                    formData.append('release', "draft");

                    var request = new XMLHttpRequest();
                    request.open("POST", "/fileserver/api/filemanager/updateXML.php");

                    request.onload = function (oEvent) {
                        if (request.status == 200) {
                            console.log(request);
                            console.log("Done!");
                            self.toast.success('Entwurf erfolgreich gespeichert.');
                        } else {
                            console.log("Error " + oReq.status);
                        }
                    };

                    request.send(formData);
                }
            }

            this.set('uploadXML', false);
            this.set('relaseXML', false);
        },

        // Actions - Button-Events, etc.
        actions: {
            logout: function logout(data) {
                this.get('authmanager').logout();
            },

            downloadDone: function downloadDone(xml) {
                this.set('response_xmlfile', xml);
                this.set('page_title_sync', $(xml).find("header").find("label").find("prefLabel").text());
                this.set('loading', false);
            },

            saveAsDraft: function saveAsDraft() {
                var self = this;

                this.set('genCount', 0);

                if (this.get('genXMLfromComponents') == false) {
                    this.set('genXMLfromComponents', true);
                } else {
                    this.set('genXMLfromComponents', false);
                }

                this.set('uploadXML', true);
                this.set('relaseXML', false);
            },

            saveAndRelease: function saveAndRelease() {
                var self = this;

                this.set('genCount', 0);

                if (this.get('genXMLfromComponents') == false) {
                    this.set('genXMLfromComponents', true);
                } else {
                    this.set('genXMLfromComponents', false);
                }

                this.set('uploadXML', true);
                this.set('relaseXML', true);
            },

            close: function close() {
                if (confirm("Möchten Sie den Editor wirklich beenden?\nDie von Ihnen vorgenommenen Änderungen werden möglicherweise nicht gespeichert.") == true) {
                    //txt = "You pressed OK!";
                    this.replaceRoute('dashboard');
                } else {
                    //txt = "You pressed Cancel!";
                }
            },

            returnXMLString_header: function returnXMLString_header(data) {
                this.set('header_xml', data);

                this.set('genCount', this.get('genCount') + 1);

                if (this.get('genCount') == 9) {
                    this.generateXML();
                }
            },

            returnXMLString_object: function returnXMLString_object(data) {
                this.set('object_xml', data);
                this.set('genCount', this.get('genCount') + 1);

                if (this.get('genCount') == 9) {
                    this.generateXML();
                }
            },

            returnXMLString_chronology: function returnXMLString_chronology(data) {
                this.set('chronology_xml', data);
                this.set('genCount', this.get('genCount') + 1);

                if (this.get('genCount') == 9) {
                    this.generateXML();
                }
            },

            returnXMLString_people_desc: function returnXMLString_people_desc(data) {
                this.set('people_desc_xml', data);
                this.set('genCount', this.get('genCount') + 1);

                if (this.get('genCount') == 9) {
                    this.generateXML();
                }
            },

            returnXMLString_shortdescs: function returnXMLString_shortdescs(data) {
                this.set('shortdescs_xml', data);
                this.set('genCount', this.get('genCount') + 1);

                if (this.get('genCount') == 9) {
                    this.generateXML();
                }
            },

            returnXMLString_currentuse: function returnXMLString_currentuse(data) {
                this.set('currentuse_xml', data);
                this.set('genCount', this.get('genCount') + 1);

                if (this.get('genCount') == 9) {
                    this.generateXML();
                }
            },

            returnXMLString_literature: function returnXMLString_literature(data) {
                this.set('literature_xml', data);
                this.set('genCount', this.get('genCount') + 1);

                if (this.get('genCount') == 9) {
                    this.generateXML();
                }
            },

            returnXMLString_refs: function returnXMLString_refs(data) {
                this.set('refs_xml', data);
                this.set('genCount', this.get('genCount') + 1);

                if (this.get('genCount') == 9) {
                    this.generateXML();
                }
            },

            returnXMLString_attachments: function returnXMLString_attachments(data) {
                this.set('attachments_xml', data);
                this.set('genCount', this.get('genCount') + 1);

                if (this.get('genCount') == 9) {
                    this.generateXML();
                }
            }
        }
    });
});