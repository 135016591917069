define('editor-burgendatenbank/components/burgeneditor-navbar', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Component.extend({
        authmanager: _ember['default'].inject.service('auth-manager'),

        user_longname: '',
        user_isAdmin: false,

        init: function init() {
            this._super();
            this.set("user_longname", this.get('authmanager').getCurrentUserName());

            if (this.get('authmanager').userIsAdmin()) {
                this.set('user_isAdmin', true);
            }
        },

        actions: {
            openNav: function openNav() {
                document.getElementById("myNav").style.width = "100%";
            },
            closeNav: function closeNav() {
                document.getElementById("myNav").style.width = "0%";
            },

            logout: function logout(data) {
                this.get('authmanager').logout();
            }
        }
    });
});