define('editor-burgendatenbank/routes/editor', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Route.extend({
        authmanager: _ember['default'].inject.service('auth-manager'),

        beforeModel: function beforeModel() {
            if (!this.get('authmanager').isAuthenticated()) {
                this.transitionTo('index');
            }
        },

        setupController: function setupController() {

            _ember['default'].$(window).on('beforeunload', function (e) {
                e.returnValue = "hi";
                return e.returnValue;
            });
        }
    });
});