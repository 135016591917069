define("editor-burgendatenbank/components/editor-literature", ["exports", "ember", "npm:xmlbuilder"], function (exports, _ember, _npmXmlbuilder) {
    exports["default"] = _ember["default"].Component.extend({
        field_publicationTFArray: [],

        init: function init() {
            this._super();
            this.field_publicationTFArray.clear();

            if (this.input.length > 0) {
                var xml = this.input;

                for (var i = 0; i < $(xml).find("literature").find("publication").length; i++) {
                    this.field_publicationTFArray.addObject({
                        name: $(xml).find("literature").find("publication")[i].innerText
                    });
                }
            }
        },

        // =========================================
        // =========================================

        generate_xml: (function () {
            if (this.field_publicationTFArray.length > 0) {
                var xml = _npmXmlbuilder["default"].create('literature');

                for (var i = 0; i < this.field_publicationTFArray.length; i++) {
                    if (this.field_publicationTFArray[i].name.length > 0) {
                        xml.ele('publication').txt(this.field_publicationTFArray[i].name);
                    }
                }
                this.sendAction('returnXMLString', xml);
            } else {
                this.sendAction('returnXMLString', '');
            }
        }).observes('runXMLGenerator'),
        // =========================================
        // =========================================

        actions: {
            add_field_publicationTF: function add_field_publicationTF() {
                this.field_publicationTFArray.addObject({
                    name: ''
                });
            },
            remove_field_publicationTF: function remove_field_publicationTF(index) {
                this.field_publicationTFArray.removeAt(index);
            }
        }

    });
});