define('editor-burgendatenbank/routes/index', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Route.extend({
        authmanager: _ember['default'].inject.service('auth-manager'),

        beforeModel: function beforeModel() {
            if (this.get('authmanager').isAuthenticated()) {
                this.transitionTo('dashboard');
            } else {
                this.transitionTo('login');
            }
        }

    });
});