define("editor-burgendatenbank/components/editor-object", ["exports", "ember", "npm:xmlbuilder"], function (exports, _ember, _npmXmlbuilder) {
    exports["default"] = _ember["default"].Component.extend({
        sync_objref_list: [],
        update: false,

        invnr_max: 0,

        init: function init() {
            this._super();

            if (this.input.length > 0) {

                this.sync_objref_list.clear();

                if ($(this.input).find("object").length > 0) {
                    var _object = $(this.input).find("object");

                    if ($(_object)[0]) {
                        for (var i = 0; i < $(_object)[0].children.length; i++) {
                            var _start_element = $(_object)[0].children[i];
                            this.sync_objref_list.addObject(this.getChildren(_start_element));
                        }
                    }
                }
            }
        },

        getChildren: function getChildren(_element) {
            var object = {};
            object.invnr = $(_element).attr("invnr");

            var res = object.invnr.split("-");
            if (parseInt(res[3]) > this.get('invnr_max')) {
                this.set('invnr_max', parseInt(res[3]));
            }

            object.collapseID = res[1] + '-' + res[2] + '-' + res[3];

            object["class"] = [];
            object["class"].addObject({
                label: $(_element).attr("class")
            });

            object.type = [];
            object.feature = [];
            object.dimensions_length = '';
            object.dimensions_width = '';
            object.dimensions_height = '';
            object.dimensions_depth = '';
            object.dimensions_unit = '';
            object.comment = '';
            object.children = [];

            for (var k = 0; k < $(_element)[0].children.length; k++) {
                var _child = $(_element)[0].children[k];

                if (_child.localName == 'type') {
                    object.type.addObject({
                        label: _child.innerText
                    });
                } else if (_child.localName == 'feature') {
                    object.feature.addObject({
                        label: _child.innerText
                    });
                } else if (_child.localName == 'dimensions') {
                    if ($(_child).attr("length")) {
                        object.dimensions_length = $(_child).attr("length");
                    }

                    if ($(_child).attr("width")) {
                        object.dimensions_width = $(_child).attr("width");
                    }

                    if ($(_child).attr("height")) {
                        object.dimensions_height = $(_child).attr("height");
                    }

                    if ($(_child).attr("depth")) {
                        object.dimensions_depth = $(_child).attr("depth");
                    }

                    if ($(_child).attr("unit")) {
                        object.dimensions_unit = $(_child).attr("unit");
                    }
                } else if (_child.localName == 'comment') {
                    object.comment = _child.innerText;
                } else if (_child.localName == 'element') {
                    object.children.addObject(this.getChildren(_child));
                }
            }

            return object;
        },

        // =========================================
        // =========================================

        generate_xml: (function () {
            if (this.sync_objref_list.length > 0) {
                var xml = _npmXmlbuilder["default"].create('object');

                for (var i = 0; i < this.sync_objref_list.length; i++) {
                    this.genChildrenXML(xml, this.sync_objref_list[i]);
                }

                //console.log(xml);
                this.sendAction('returnXMLString', xml);
            } else {
                this.sendAction('returnXMLString', '');
            }
        }).observes('runXMLGenerator'),

        genChildrenXML: function genChildrenXML(_parent, _obj) {
            var _element = _parent.ele('element');
            _element.att('invnr', _obj.invnr);

            _element.att('class', _obj["class"][0].label);

            for (var k = 0; k < _obj.type.length; k++) {
                _element.ele('type').txt(_obj.type[k].label);
            }

            for (var k = 0; k < _obj.feature.length; k++) {
                _element.ele('feature').txt(_obj.feature[k].label);
            }

            if (_obj.dimensions_unit || _obj.dimensions_depth || _obj.dimensions_height || _obj.dimensions_length || _obj.dimensions_width) {
                var dimensions = _element.ele('dimensions');

                if (_obj.dimensions_length) {
                    dimensions.att('length', _obj.dimensions_length);
                }

                if (_obj.dimensions_width) {
                    dimensions.att('width', _obj.dimensions_width);
                }

                if (_obj.dimensions_height) {
                    dimensions.att('height', _obj.dimensions_height);
                }

                if (_obj.dimensions_depth) {
                    dimensions.att('depth', _obj.dimensions_depth);
                }

                if (_obj.dimensions_unit) {
                    dimensions.att('unit', _obj.dimensions_unit);
                }
            }

            if (_obj.comment) {
                _element.ele('comment').txt(_obj.comment);
            }

            for (var k = 0; k < _obj.children.length; k++) {
                this.genChildrenXML(_element, _obj.children[k]);
            }
        },
        // =========================================
        // =========================================

        actions: {
            add_element: function add_element() {
                var object = {};

                this.set('invnr_max', this.invnr_max + 1);
                var invnr_string = '000' + this.invnr_max;

                if (this.invnr_max >= 10 && this.invnr_max <= 99) {
                    invnr_string = '00' + this.invnr_max;
                } else if (this.invnr_max >= 100 && this.invnr_max <= 999) {
                    invnr_string = '0' + this.invnr_max;
                } else if (this.invnr_max >= 1000) {
                    invnr_string = this.invnr_max;
                }

                object.invnr = this.county_abbr + '-o-' + this.county_id + '-' + invnr_string;
                object.collapseID = 'o-' + this.county_id + '-' + invnr_string;
                object["class"] = [];
                object["class"].addObject({
                    label: ''
                });
                object.type = [];
                object.feature = [];
                object.dimensions_length = '';
                object.dimensions_width = '';
                object.dimensions_height = '';
                object.dimensions_depth = '';
                object.dimensions_unit = '';
                object.comment = '';
                object.children = [];

                this.sync_objref_list.addObject(object);
            },

            remove_element: function remove_element(index) {
                this.sync_objref_list.removeAt(index);
            }
        }

    });
});