define('editor-burgendatenbank/controllers/dashboard', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Controller.extend({
        authmanager: _ember['default'].inject.service('auth-manager'),

        user_longname: '',
        user_isAdmin: false,

        init: function init() {
            this._super();
            this.set("user_longname", this.get('authmanager').getCurrentUserName());

            if (this.get('authmanager').userIsAdmin()) {
                this.set('user_isAdmin', true);
            }
        },

        reopen: function reopen() {
            this.init();
        },

        actions: {
            logout: function logout(data) {
                this.get('authmanager').logout();
            }
        }
    });
});