define('editor-burgendatenbank/components/editor-currentuse', ['exports', 'ember', 'npm:xmlbuilder', 'fetch'], function (exports, _ember, _npmXmlbuilder, _fetch) {
    exports['default'] = _ember['default'].Component.extend({

        property_list: [],
        property_select: {},

        property_name: '',

        accessibility_list: [],
        accessibility_select: {},

        wedding_possible: false,

        init: function init() {
            this._super();
            this.init_vocab();
            this.init_xml();
        },

        init_vocab: function init_vocab() {
            this.property_list.clear();
            this.accessibility_list.clear();

            this.set('property_list', (0, _fetch['default'])('/fileserver/api/vocabulary/getVocab.php?group=tourist-info&target-element=tourist-info&target-attribute=property').then(function (response) {
                return response.json();
            }));

            this.set('accessibility_list', (0, _fetch['default'])('/fileserver/api/vocabulary/getVocab.php?group=tourist-info&target-element=tourist-info&target-attribute=accessibility').then(function (response) {
                return response.json();
            }));
        },

        // =========================================
        // =========================================
        init_xml: function init_xml() {
            if (this.input.length > 0) {
                var xml = this.input;

                if ($(xml).find("tourist-inf").attr("property")) {
                    this.set('property_select.label', $(xml).find("tourist-inf").attr("property"));
                }

                if ($(xml).find("tourist-inf").attr("property-name")) {
                    this.set('property_name', $(xml).find("tourist-inf").attr("property-name"));
                }

                if ($(xml).find("tourist-inf").attr("accessibility")) {
                    this.set('accessibility_select.label', $(xml).find("tourist-inf").attr("accessibility"));
                }

                if ($(xml).find("tourist-inf").attr("wedding")) {
                    this.set('wedding_possible', $(xml).find("tourist-inf").attr("wedding"));
                }
            }
        },
        // =========================================
        // =========================================

        generate_xml: (function () {

            this.set('error', '');

            var tourist_inf = _npmXmlbuilder['default'].create('tourist-inf');

            if (this.get('property_select.label')) {
                tourist_inf.att('property', this.get('property_select.label'));
            }

            if (this.get('property_name')) {
                tourist_inf.att('property-name', this.get('property_name'));
            }

            if (this.get('accessibility_select.label')) {
                tourist_inf.att('accessibility', this.get('accessibility_select.label'));
            }

            if (this.get('wedding_possible')) {
                tourist_inf.att('wedding', this.get('wedding_possible'));
            }

            this.sendAction('returnXMLString', tourist_inf);
        }).observes('runXMLGenerator'),
        // =========================================
        // =========================================

        actions: {}

    });
});