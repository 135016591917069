define('editor-burgendatenbank/components/editor-history', ['exports', 'ember', 'npm:xmlbuilder'], function (exports, _ember, _npmXmlbuilder) {
    exports['default'] = _ember['default'].Component.extend({
        familynr_max: 0,

        init: function init() {
            this._super();
            this.init_xml();
        },

        sync_familyref_list: [],
        sync_familyref_list_highestID: '',

        family_role_list: [],

        // =========================================
        // =========================================

        downloadvocab: false,

        focusIn: function focusIn() {
            if (!this.downloadvocab) {
                this.init_vocab();
                this.downloadvocab = true;
            }
        },

        init_vocab: function init_vocab() {
            this.family_role_list.clear();

            this.set('family_role_list', fetch('/fileserver/api/vocabulary/getVocab.php?group=persons&target-element=family&target-attribute=role').then(function (response) {
                return response.json();
            }));
        },

        // =========================================
        // =========================================
        init_xml: function init_xml() {
            if (this.input.length > 0) {
                var xml = this.input;

                this.sync_familyref_list.clear();

                for (var i = 0; i < $(xml).find("persons").find("family").length; i++) {
                    var _family = $(xml).find("persons").find("family")[i];
                    this.sync_familyref_list.addObject({
                        name: $(_family).text(),
                        role: {
                            label: $(_family).attr("role")
                        },
                        familynr: $(_family).attr("familynr")
                    });

                    var res = $(_family).attr("familynr").split("-");
                    if (parseInt(res[3]) > this.get('familynr_max')) {
                        this.set('familynr_max', parseInt(res[3]));
                    }
                }
            }
        },
        // =========================================
        // =========================================

        generate_xml: (function () {
            if (this.sync_familyref_list.length > 0) {
                var xml = _npmXmlbuilder['default'].create('persons');

                for (var i = 0; i < this.sync_familyref_list.length; i++) {
                    var _family = xml.ele('family');
                    _family.att('familynr', this.sync_familyref_list[i].familynr);
                    _family.att('role', this.sync_familyref_list[i].role.label);
                    _family.txt(this.sync_familyref_list[i].name);
                }

                this.sendAction('returnXMLString', xml);
            } else {
                this.sendAction('returnXMLString', '');
            }
        }).observes('runXMLGenerator'),
        // =========================================
        // =========================================

        actions: {
            add_family: function add_family() {
                var family = {};

                this.familynr_max += 1;
                var familynr_string = '000' + this.familynr_max;

                if (this.familynr_max >= 10 && this.familynr_max <= 99) {
                    familynr_string = '00' + this.familynr_max;
                } else if (this.familynr_max >= 100 && this.familynr_max <= 999) {
                    familynr_string = '0' + this.familynr_max;
                } else if (this.familynr_max >= 1000) {
                    familynr_string = this.familynr_max;
                }

                family.familynr = this.county_abbr + '-f-' + this.county_id + '-' + familynr_string;
                family.name = '';
                family.role = '';

                this.sync_familyref_list.addObject(family);
            },
            remove_family: function remove_family(index) {
                this.sync_familyref_list.removeAt(index);
            }
        }

    });
});