define('editor-burgendatenbank/services/auth-manager', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Service.extend({
        routing: _ember['default'].inject.service('-routing'),

        isAdmin: false,

        user_list: [{
            name: 'Tobias Arera-Rütenik',
            role: 'Admin',
            email: 'tobias.arera-ruetenik@uni-bamberg.de'
        }, {
            name: 'Manuel Hunziker',
            role: 'Admin',
            email: 'manuel.hunziker@uni-bamberg.de'
        }, {
            name: 'Volker Rößner',
            role: 'Editor',
            email: 'vroessner@web.de'
        }, {
            name: 'Lisa Bleck',
            role: 'Editor',
            email: 'Lisa.Bleck@gmx.net'
        }, {
            name: 'Clara Forcht',
            role: 'Editor',
            email: 'c.forcht@t-online.de'
        }, {
            name: 'Simon Schmidt',
            role: 'Editor',
            email: 'simon-dirk.schmidt@stud.uni-bamberg.de'
        }],

        init: function init() {},

        login: function login(username, password) {
            for (var i = 0; i < this.user_list.length; i++) {
                if (this.get('user_list')[i].name == username) {
                    localStorage.setItem('user_name', this.get('user_list')[i].name);
                    localStorage.setItem('user_role', this.get('user_list')[i].role);
                    localStorage.setItem('user_email', this.get('user_list')[i].email);

                    return true;
                }
            }

            return false;
        },

        getCurrentUserName: function getCurrentUserName() {
            if (this.isAuthenticated()) {
                return localStorage.getItem('user_name');
            } else {
                return '';
            }
        },

        getCurrentUserEmail: function getCurrentUserEmail() {
            if (this.isAuthenticated()) {
                return localStorage.getItem('user_email');
            } else {
                return '';
            }
        },

        getCurrentUserRole: function getCurrentUserRole() {
            if (this.isAuthenticated()) {
                return localStorage.getItem('user_role');
            } else {
                return '';
            }
        },

        userIsAdmin: function userIsAdmin() {
            if (this.isAuthenticated()) {
                if (localStorage.getItem('user_role') == 'Admin') {
                    this.set('isAdmin', true);
                    return true;
                }
            } else {
                this.set('isAdmin', false);
                return false;
            }
        },

        logout: function logout() {
            localStorage.clear();
            window.location.reload(true);
            this.get("routing").transitionTo("login");
        },

        isAuthenticated: function isAuthenticated() {
            if (localStorage.getItem('user_name') && localStorage.getItem('user_role') && localStorage.getItem('user_email')) {
                return true;
            } else {
                return false;
            }
        }

    });
});