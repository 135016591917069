define('editor-burgendatenbank/components/editor-object-element', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Component.extend({
        class_list: [],
        type_list: [],
        feature_list: [],

        unit_list: ['km', 'm', 'cm', 'mm'],

        init: function init() {
            this._super();

            this.class_list.clear();
            this.type_list.clear();
            this.feature_list.clear();

            /*this.updateClassList();
            this.updateTypeList();
            this.updateFeatureList();*/
        },

        downloadvocab: false,

        focusIn: function focusIn() {
            if (!this.downloadvocab) {
                this.updateClassList();
                this.updateTypeList();
                this.updateFeatureList();
                this.downloadvocab = true;
            }
        },

        updateTypeList: function updateTypeList() {
            var _temp_class = '';
            for (var k = 0; k < this['class'].length; k++) {
                if (k > 0) {
                    _temp_class += ',';
                }

                _temp_class += this['class'][k].label;
            }

            var _temp_parent_type = '';
            for (var k = 0; k < this.parent_type.length; k++) {
                if (k > 0) {
                    _temp_parent_type += ',';
                }
                _temp_parent_type += this.parent_type[k].label;
            }

            this.set('type_list', fetch('/fileserver/api/vocabulary/getVocab.php?group=object&target-element=type&when-class=' + _temp_class + '&when-parent-type=' + _temp_parent_type).then(function (response) {
                return response.json();
            }));
        },

        updateClassList: function updateClassList() {
            this.set('class_list', fetch('/fileserver/api/vocabulary/getVocab.php?group=object&target-element=element&target-attribute=class').then(function (response) {
                return response.json();
            }));
        },

        updateFeatureList: function updateFeatureList() {

            var _temp_class = '';
            for (var k = 0; k < this['class'].length; k++) {
                if (k > 0) {
                    _temp_class += ',';
                }

                _temp_class += this['class'][k].label;
            }

            var _temp_type = '';
            for (var k = 0; k < this.type.length; k++) {
                if (k > 0) {
                    _temp_type += ',';
                }
                _temp_type += this.type[k].label;
            }

            var _temp_parent_type = '';
            for (var k = 0; k < this.parent_type.length; k++) {
                if (k > 0) {
                    _temp_parent_type += ',';
                }
                _temp_parent_type += this.parent_type[k].label;
            }

            this.set('feature_list', fetch('/fileserver/api/vocabulary/getVocab.php?group=object&target-element=feature&when-class=' + _temp_class + '&when-type=' + _temp_type + '&when-parent-type=' + _temp_parent_type).then(function (response) {
                return response.json();
            }));
        },

        observerParentTypeUpdate: (function () {}).observes('update'),

        actions: {
            chooseClass: function chooseClass(index, _c) {
                this['class'].removeAt(index);
                this['class'].splice(index, 0, _c);

                this.type.clear();
                this.updateTypeList();
                this.feature.clear();
                this.updateFeatureList();

                //update children
                if (this.update) {
                    this.set('update', false);
                } else {
                    this.set('update', true);
                }
            },

            chooseType: function chooseType(index, _c) {
                this.type.removeAt(index);
                this.type.splice(index, 0, _c);

                this.feature.clear();
                this.updateFeatureList();

                //update children
                if (this.update) {
                    this.set('update', false);
                } else {
                    this.set('update', true);
                }
            },

            add_field_type: function add_field_type() {
                this.type.addObject({
                    label: ''
                });
            },
            remove_field_type: function remove_field_type(index) {
                this.updateFeatureList();
                this.type.removeAt(index);
            },

            chooseFeature: function chooseFeature(index, _c) {
                this.feature.removeAt(index);
                this.feature.splice(index, 0, _c);
            },

            add_field_feature: function add_field_feature() {
                this.feature.addObject({
                    label: ''
                });
            },
            remove_field_feature: function remove_field_feature(index) {
                this.feature.removeAt(index);
            },

            add_child_element: function add_child_element() {
                var object = {};

                this.set('invnr_max', this.invnr_max + 1);
                var invnr_string = '000' + this.invnr_max;

                if (this.invnr_max >= 10 && this.invnr_max <= 99) {
                    invnr_string = '00' + this.invnr_max;
                } else if (this.invnr_max >= 100 && this.invnr_max <= 999) {
                    invnr_string = '0' + this.invnr_max;
                } else if (this.invnr_max >= 1000) {
                    invnr_string = this.invnr_max;
                }

                object.invnr = this.county_abbr + '-o-' + this.county_id + '-' + invnr_string;
                object.collapseID = 'o-' + this.county_id + '-' + invnr_string;

                object['class'] = [];
                object['class'].addObject({
                    label: ''
                });

                object.type = [];
                object.feature = [];
                object.dimensions_length = '';
                object.dimensions_width = '';
                object.dimensions_height = '';
                object.dimensions_depth = '';
                object.dimensions_unit = '';
                object.comment = '';
                object.children = [];

                this.children.addObject(object);
            },

            remove_element: function remove_element(index) {
                this.children.removeAt(index);
            }
        }
    });
});