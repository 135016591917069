define('editor-burgendatenbank/controllers/admin', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Controller.extend({
        toast: _ember['default'].inject.service(),
        downloadmanager: _ember['default'].inject.service('download-manager'),

        loading: true,

        init: function init() {
            this.set('loading', false);
        },

        actions: {

            downloadDone: function downloadDone(xml) {
                this.toast.info('XML-Datei erfolgreich importiert.');

                this.set('vocab', xml);

                /*this.set('loading', false);
                this.refreshGUI();*/
            },

            downloadVocab: function downloadVocab() {
                this.get('downloadmanager').init('/fileserver/api/vocabulary/getVocabFile.php', this);
            },

            uploadVocab: function uploadVocab() {}

        }

    });
});