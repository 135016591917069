define('editor-burgendatenbank/controllers/create', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Controller.extend({
        state_list: [],
        state_select: {
            label: 'Bundesrepublik Deutschland'
        },

        federal_state_list: [],
        federal_state_select: {
            label: 'Bayern'
        },

        region_list: [],
        region_select: {},

        county_list: [],
        county_select: {},

        loading: false,

        init: function init() {
            this._super();

            this.state_list.clear();
            this.federal_state_list.clear();
            this.region_list.clear();
            this.county_list.clear();

            /*this.set('state_list', this.get('vocab_manager').getVocabList('header', 'state', '', '', '', ''));
            this.set('federal_state_list', this.get('vocab_manager').getVocabList('header', 'federal-state', '', '', '', ''));
            this.set('region_list', this.get('vocab_manager').getVocabList('header', 'region', '', '', '', ''));
            this.set('county_list', this.get('vocab_manager').getVocabList('header', 'county', '', '', '', ''));*/

            this.set('state_list', fetch('/fileserver/api/vocabulary/getVocab.php?group=header&target-element=state').then(function (response) {
                return response.json();
            }));
            this.set('federal_state_list', fetch('/fileserver/api/vocabulary/getVocab.php?group=header&target-element=federal-state').then(function (response) {
                return response.json();
            }));
            this.set('region_list', fetch('/fileserver/api/vocabulary/getVocab.php?group=header&target-element=region').then(function (response) {
                return response.json();
            }));
            this.set('county_list', fetch('/fileserver/api/vocabulary/getVocab.php?group=header&target-element=county').then(function (response) {
                return response.json();
            }));
        },

        reopen: function reopen() {
            this.init();
        },

        actions: {
            chooseState: function chooseState(_s) {
                this.set('state_select', _s);
            },

            chooseFederalState: function chooseFederalState(_fs) {
                this.set('federal_state_select', _fs);
            },

            chooseRegion: function chooseRegion(_r) {
                this.set('region_select', _r);
            },

            chooseCounty: function chooseCounty(_c) {
                this.set('county_select', _c);
            },

            createCastle: function createCastle(startEditor) {
                var self = this;
                var xhttp = new XMLHttpRequest();
                xhttp.onreadystatechange = function () {
                    if (this.readyState == 4 && this.status == 200) {
                        console.log(this.responseText);
                        self.set('loading', false);

                        if (this.responseText == 'error') {
                            self.toast.error('Es ist ein Fehler aufgetreten. Datensatz konnte nicht angelegt werden. :-(');
                        } else {
                            //self.replaceRoute('editor?url=' + this.responseText);
                            self.toast.success('Der Burgendatensatz wurde erfolgreich angelegt. :-) Juhu!');

                            if (startEditor) {
                                self.replaceRoute('editor', { queryParams: { url: this.responseText } });
                            } else {
                                //self.replaceRoute('create');
                                self.set('field_prefLabel', '');
                                self.set('state_select.label', 'Bundesrepublik Deutschland');
                                self.set('federal_state_select.label', 'Bayern');
                                self.set('region_select.label', '');
                                self.set('county_select.label', '');
                            }
                        }
                    } else if (this.status == 500 || this.status == 404) {
                        self.set('loading', false);
                        self.toast.error('Es ist ein Fehler aufgetreten. Datensatz konnte nicht angelegt werden. :-(');
                    }
                };
                xhttp.open("GET", "/fileserver/api/filemanager/create.php?state=" + this.get('state_select.label') + "&federal-state=" + this.get('federal_state_select.label') + "&region=" + this.get('region_select.label') + "&county=" + this.get('county_select.label') + "&prefLabel=" + this.get('field_prefLabel') + "&abbr=" + this.get('county_select.abbr').replace("+", "%2B"), true);

                if (this.get('field_prefLabel') && this.get('state_select.label') && this.get('federal_state_select.label') && this.get('region_select.label') && this.get('county_select.label')) {
                    xhttp.send();
                    self.set('loading', true);
                } else {}
            }
        }
    });
});