define('editor-burgendatenbank/components/kdwt-header', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Component.extend({
        style: 'dark',
        css_id: 'ofu-banner-dark',
        ub_logo: 'ub_logo_weiss.png',
        kdwt_logo: 'KDWT-Logo-dunkel-groß.png',

        init: function init() {
            this._super();

            if (this.style == 'black') {
                this.css_id = 'ofu-banner-black';
                this.ub_logo = 'ub_logo_weiss.png';
                this.kdwt_logo = 'KDWT-Logo-dunkel-groß.png';
            } else if (this.style == 'light') {
                this.css_id = 'ofu-banner-light';
                this.ub_logo = 'ub_logo_blau.png';
                this.kdwt_logo = 'KDWT-Logo-hell-groß.png';
            } else if (this.style == 'beige') {
                this.css_id = 'ofu-banner-beige';
                this.ub_logo = 'ub_logo_schwarz.png';
                this.kdwt_logo = 'KDWT-Logo-hell-groß_black.png';
            } else {
                this.css_id = 'ofu-banner-dark';
                this.ub_logo = 'ub_logo_weiss.png';
                this.kdwt_logo = 'KDWT-Logo-dunkel-groß.png';
            }
        }
    });
});