define('editor-burgendatenbank/components/editor-header', ['exports', 'ember', 'npm:xmlbuilder', 'fetch'], function (exports, _ember, _npmXmlbuilder, _fetch) {
    exports['default'] = _ember['default'].Component.extend({
        authmanager: _ember['default'].inject.service('auth-manager'),

        init: function init() {
            this._super();
            this.init_xml();
        },

        field_altLabelArray: [],
        creatorList: [],

        state_list: [],
        state_select: {
            label: 'Bundesrepublik Deutschland'
        },

        federal_state_list: [],
        federal_state_select: {
            label: 'Bayern'
        },

        region_list: [],
        region_select: {},

        county_list: [],
        county_select: {},

        field_denkmalnummerArray: [],

        buttonGroupValue2: false,
        toast: _ember['default'].inject.service(),

        bool_showGeo: true,
        field_wgs84_lat: 0.0,
        field_wgs84_lon: 0.0,
        zoom: 16,

        // =========================================
        // =========================================

        downloadvocab: false,

        focusIn: function focusIn() {
            if (!this.downloadvocab) {
                this.init_vocab();
                this.downloadvocab = true;
            }
        },

        init_vocab: function init_vocab() {
            this.state_list.clear();
            this.federal_state_list.clear();
            this.region_list.clear();
            this.county_list.clear();

            this.set('state_list', (0, _fetch['default'])('/fileserver/api/vocabulary/getVocab.php?group=header&target-element=state').then(function (response) {
                return response.json();
            }));
            this.set('federal_state_list', (0, _fetch['default'])('/fileserver/api/vocabulary/getVocab.php?group=header&target-element=federal-state').then(function (response) {
                return response.json();
            }));
            this.set('region_list', (0, _fetch['default'])('/fileserver/api/vocabulary/getVocab.php?group=header&target-element=region').then(function (response) {
                return response.json();
            }));
            this.set('county_list', (0, _fetch['default'])('/fileserver/api/vocabulary/getVocab.php?group=header&target-element=county').then(function (response) {
                return response.json();
            }));
        },

        // =========================================
        // =========================================
        init_xml: function init_xml() {
            if (this.input.length > 0) {
                var xml = this.input;

                this.set('field_prefLabel', $(xml).find("header").find("label").find("prefLabel").text());

                this.field_altLabelArray.clear();

                for (var i = 0; i < $(xml).find("header").find("label").find("altLabel").length; i++) {
                    this.field_altLabelArray.addObject({
                        name: $(xml).find("header").find("label").find("altLabel")[i].innerText
                    });
                }

                this.set('state_select.label', $(xml).find("header").find("location").find("state").text());
                this.set('federal_state_select.label', $(xml).find("header").find("location").find("federal-state").text());
                this.set('region_select.label', $(xml).find("header").find("location").find("region").text());
                this.set('county_select.label', $(xml).find("header").find("location").find("county").text());
                this.set('field_place', $(xml).find("header").find("location").find("place").text());
                this.set('field_district', $(xml).find("header").find("location").find("district").text());

                if ($(xml).find("header").find("location").find("geolocation").attr("show")) {
                    this.set('bool_showGeo', $(xml).find("header").find("location").find("geolocation").attr("show"));
                }

                if ($(xml).find("header").find("location").find("geolocation").find("WGS84").attr("lat")) {
                    this.set('field_wgs84_lat', $(xml).find("header").find("location").find("geolocation").find("WGS84").attr("lat"));
                }

                if ($(xml).find("header").find("location").find("geolocation").find("WGS84").attr("lon")) {
                    this.set('field_wgs84_lon', $(xml).find("header").find("location").find("geolocation").find("WGS84").attr("lon"));
                }

                this.creatorList.clear();

                for (var i = 0; i < $(xml).find("header").find("edition").find("creator").length; i++) {
                    this.creatorList.addObject({
                        name: $(xml).find("header").find("edition").find("creator")[i].innerText
                    });
                }

                this.field_denkmalnummerArray.clear();

                for (var i = 0; i < $(xml).find("header").find("bayernAtlas").find("invnr").length; i++) {
                    this.field_denkmalnummerArray.addObject({
                        nummer: $(xml).find("header").find("bayernAtlas").find("invnr")[i].innerText
                    });
                }
            }
        },
        // =========================================
        // =========================================

        generate_xml: (function () {

            this.set('error', '');

            var header = _npmXmlbuilder['default'].create('header');
            var label = header.ele('label');

            if (this.get('field_prefLabel')) {
                label.ele('prefLabel').txt(this.get('field_prefLabel'));
            } else {
                this.set('error', this.get('error') + '<br />Objektname fehlt!');
            }

            for (var i = 0; i < this.field_altLabelArray.length; i++) {
                label.ele('altLabel').txt(this.field_altLabelArray[i].name);
            }

            //Location ----------------------------------------------------------
            var location = header.ele('location');

            if (this.get('state_select.label')) {
                location.ele('state').txt(this.get('state_select.label'));
            }

            if (this.get('federal_state_select.label')) {
                location.ele('federal-state').txt(this.get('federal_state_select.label'));
            }

            if (this.get('region_select.label')) {
                location.ele('region').txt(this.get('region_select.label'));
            }

            if (this.get('county_select.label')) {
                location.ele('county').txt(this.get('county_select.label'));
            }

            if (this.get('field_place')) {
                location.ele('place').txt(this.get('field_place'));
            }

            if (this.get('field_district')) {
                location.ele('district').txt(this.get('field_district'));
            }

            //-----------------------------------------------------------------------

            var geolocation = location.ele('geolocation');
            geolocation.att('show', this.get('bool_showGeo'));

            if (this.get('field_wgs84_lat') && this.get('field_wgs84_lon')) {
                geolocation.ele('WGS84').att('lat', this.get('field_wgs84_lat')).att('lon', this.get('field_wgs84_lon'));
            } else {
                if (this.get('field_wgs84_lat')) {
                    geolocation.ele('WGS84').att('lat', this.get('field_wgs84_lat'));
                }

                if (this.get('field_wgs84_lon')) {
                    geolocation.ele('WGS84').att('lon', this.get('field_wgs84_lon'));
                }
            }

            var bayernAtlas = header.ele('bayernAtlas');
            for (var i = 0; i < this.field_denkmalnummerArray.length; i++) {
                bayernAtlas.ele('invnr').txt(this.field_denkmalnummerArray[i].nummer);
            }

            var meta = header.ele('edition');
            meta.ele('publisher').txt('KDWT, Universität Bamberg');
            meta.ele('publisher').txt('Europäisches Burgeninstitut');

            var creator_exist = false;

            var user_name = this.get('authmanager').getCurrentUserName();

            for (var i = 0; i < this.creatorList.length; i++) {
                meta.ele('creator').txt(this.creatorList[i].name);

                if (this.creatorList[i].name == user_name) {
                    creator_exist = true;
                }
            }

            if (!creator_exist) {
                meta.ele('creator').txt(user_name);
            }

            var today = new Date();
            meta.ele('date').txt(today.getFullYear() + '-' + today.getMonth() + '-' + today.getDate());

            this.sendAction('returnXMLString', header);
        }).observes('runXMLGenerator'),
        // =========================================
        // =========================================

        actions: {
            add_field_altLabel: function add_field_altLabel() {
                this.field_altLabelArray.addObject({
                    name: ''
                });
            },

            remove_field_altLabel: function remove_field_altLabel(index) {
                this.field_altLabelArray.removeAt(index);
            },

            chooseState: function chooseState(_s) {
                this.set('state_select', _s);
            },

            chooseFederalState: function chooseFederalState(_fs) {
                this.set('federal_state_select', _fs);
            },

            chooseRegion: function chooseRegion(_r) {
                this.set('region_select', _r);
            },

            chooseCounty: function chooseCounty(_c) {
                this.set('county_select', _c);
            },

            updateLocation: function updateLocation(e) {
                var self = this;
                var location = e.target.getLatLng();
                self.set('field_wgs84_lat', location.lat);
                self.set('field_wgs84_lon', location.lng);
            },

            getGPSPosFromAdress: function getGPSPosFromAdress() {
                var self = this;
                var xhttp = new XMLHttpRequest();
                xhttp.onreadystatechange = function () {
                    if (this.readyState == 4 && this.status == 200) {
                        var data = JSON.parse(this.responseText);
                        self.set('loading', false);
                        self.set('field_wgs84_lat', data.results[0].geometry.location.lat);
                        self.set('field_wgs84_lon', data.results[0].geometry.location.lng);
                    }
                };
                xhttp.open("GET", "http://maps.googleapis.com/maps/api/geocode/json?sensor=true&address=" + self.get('field_adress_to_gps'), true);

                if (self.get('field_adress_to_gps')) {
                    xhttp.send();
                    self.set('loading', true);
                }
            },

            add_field_denkmalnummer: function add_field_denkmalnummer() {
                this.field_denkmalnummerArray.addObject({
                    nummer: ''
                });
            },

            remove_field_denkmalnummer: function remove_field_denkmalnummer(index) {
                this.field_denkmalnummerArray.removeAt(index);
            }
        }

    });
});