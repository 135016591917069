define('editor-burgendatenbank/controllers/vocab', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Controller.extend({
        toast: _ember['default'].inject.service(),
        downloadmanager: _ember['default'].inject.service('download-manager'),

        loading: true,

        init: function init() {
            this.send('downloadVocab');
        },

        actions: {

            downloadDone: function downloadDone(xml) {

                this.set('vocab', xml);

                this.set('loading', false);

                /*this.set('loading', false);
                this.refreshGUI();*/
            },

            downloadVocab: function downloadVocab() {
                this.set('loading', true);
                this.get('downloadmanager').init('/fileserver/api/vocabulary/getVocabFile.php', this);
            },

            updateVocab: function updateVocab() {

                var self = this;
                var formData = new FormData();
                formData.append('xmlCode', this.get('vocab'));

                var request = new XMLHttpRequest();
                request.open("POST", "/fileserver/api/vocabulary/updateVocabFile.php");

                request.onload = function (oEvent) {
                    if (request.status == 200) {
                        console.log(request);
                        console.log("Done!");
                        self.toast.success('Vokabular erfolgreich gespeichert.');
                        //self.replaceRoute('dashboard');
                    } else {
                            console.log("Error " + oReq.status);
                        }
                };

                request.send(formData);
            },

            uploadVocab: function uploadVocab(files, resetInput) {

                var self = this;
                console.log(files);

                if (files) {
                    console.log(files[0]);
                    var formData = new FormData();
                    formData.append("datei", files[0]);

                    var request = new XMLHttpRequest();
                    request.open("POST", "/fileserver/api/vocabulary/uploadVocabFile.php");

                    request.onload = function (oEvent) {
                        if (request.status == 200) {

                            console.log(this.responseText);

                            var data = JSON.parse(this.responseText);
                            //console.log(data[0]["web-link"]);
                            self.set('loading', false);

                            if (data[0]["status"] == "ok") {
                                console.log("Done!");
                                self.toast.success('Vokabular erfolgreich hochgeladen.');

                                self.send('downloadVocab');
                            } else {
                                console.log("Done!");
                                self.toast.warning(data[0]["message"]);
                            }
                        } else {
                            self.set('loading', false);
                            self.toast.error('Datei konnte nicht erfolgreich hochgeladen werden.');
                            console.log("Error " + oReq.status + " occurred when trying to upload your file.");
                        }
                    };

                    request.send(formData);
                    self.set('loading', true);
                }

                // Do something with your files.
                // Once you're done, call the reset method:
                resetInput();
                // Now your input is reset!
            }

        }

    });
});