define('editor-burgendatenbank/controllers/filebrowser', ['exports', 'ember', 'fetch'], function (exports, _ember, _fetch) {
    exports['default'] = _ember['default'].Controller.extend({
        toast: _ember['default'].inject.service(),
        authmanager: _ember['default'].inject.service('auth-manager'),
        downloadmanager: _ember['default'].inject.service('download-manager'),

        user_isAdmin: false,

        breadcrumbs: [],
        grid: true,

        loading: true,
        foldername: 'Burgen verwalten',

        file_list: [],

        rename_txtfield: '',
        old_filename_temp: '',

        init: function init() {
            this.set('loading', true);

            if (this.get('authmanager').userIsAdmin()) {
                this.set('user_isAdmin', true);
            }

            this.file_list.clear();
            this.breadcrumbs.clear();

            this.send('startDownload');
        },

        actions: {

            openDirFromBreadcrumb: function openDirFromBreadcrumb(el) {
                var breadcrumbsTemp = [];

                for (var i = 0; i <= this.breadcrumbs.indexOf(el); i++) {
                    breadcrumbsTemp.push(this.breadcrumbs[i]);
                }

                this.breadcrumbs.clear();
                this.set('breadcrumbs', breadcrumbsTemp);

                this.send('startDownload');
            },

            openRootDir: function openRootDir() {
                this.breadcrumbs.clear();
                this.send('startDownload');
            },

            openDir: function openDir(el) {

                if (!el.contain_xml) {
                    this.breadcrumbs.push(el.name);
                    this.send('startDownload');
                } else {

                    this.set('loading', true);
                    var path = '';
                    for (var i = 0; i < this.breadcrumbs.length; i++) {
                        path += this.breadcrumbs[i] + '/';
                    }

                    console.log('/fileserver/files/' + path + '/' + el.name);

                    this.replaceRoute('editor', {
                        queryParams: {
                            url: '/fileserver/files/' + path + '/' + el.name
                        }
                    });
                }
            },

            startDownload: function startDownload() {
                this.set('loading', true);

                var path = '';
                for (var i = 0; i < this.breadcrumbs.length; i++) {
                    path += this.breadcrumbs[i] + '/';
                }

                console.log(path);

                this.get('downloadmanager').init('/fileserver/api/filemanager/showFolder.php?path=' + path, this);
            },

            downloadDone: function downloadDone(file_list) {
                this.set('foldername', 'Burgen verwalten');

                if (this.breadcrumbs[this.breadcrumbs.length - 1]) {
                    this.set('foldername', this.breadcrumbs[this.breadcrumbs.length - 1]);
                }

                this.set('file_list', JSON.parse(file_list));

                this.set('loading', false);
            },

            changeView: function changeView() {
                this.set('grid', !this.get('grid'));
            },

            downloadXMLFile: function downloadXMLFile(el) {
                var path = '';
                for (var i = 0; i < this.breadcrumbs.length; i++) {
                    path += this.breadcrumbs[i] + '/';
                }

                var url = '/fileserver/files/' + path + '/' + el.name;

                var folder_split = url.split("/");
                var foldername = folder_split[folder_split.length - 1];
                var foldername_split = foldername.split("-");
                var xmlfile = foldername_split[2] + '.xml';

                var downloadUrl = url + '/' + xmlfile + '?' + Math.random() * 100;

                console.log(downloadUrl);

                window.open(downloadUrl, 'Download');
            },

            downloadFolder: function downloadFolder(el) {
                var path = '';
                for (var i = 0; i < this.breadcrumbs.length; i++) {
                    path += this.breadcrumbs[i] + '/';
                }

                var downloadUrl = '/fileserver/api/filemanager/downloadFolderAsZip.php?path=' + path + '/' + el.name;

                window.open(downloadUrl, 'Download');
            },

            removeFileFolder: function removeFileFolder(el) {

                var r = confirm("Möchte Sie wirklich die Datei bzw. den Ordner löschen?");
                if (r == true) {
                    var path = '';
                    for (var i = 0; i < this.breadcrumbs.length; i++) {
                        path += this.breadcrumbs[i] + '/';
                    }

                    console.log(path + '/' + el.name);

                    var self = this;
                    var xhttp = new XMLHttpRequest();
                    xhttp.onreadystatechange = function () {
                        if (this.readyState == 4 && this.status == 200) {
                            //var data = JSON.parse(this.responseText);
                            self.set('loading', false);
                            self.send('startDownload');

                            self.toast.error('Datei / Ordner wurde gelöscht. Von nun an gibt es kein Zurück!');
                        }
                    };
                    xhttp.open("GET", '/fileserver/api/filemanager/removeDir.php?path=' + path + '/' + el.name, true);

                    xhttp.send();
                    self.set('loading', true);
                } else {
                    this.toast.success('Vorgang abgebrochen. Puh! Da haben wir aber nochmal Glück gehabt.');
                }
            },

            renameFileFolderModal: function renameFileFolderModal(el) {
                this.set('old_filename_temp', el.name);
                this.set('rename_txtfield', el.name);
            },

            renameFileFolder: function renameFileFolder() {
                var path = '';
                for (var i = 0; i < this.breadcrumbs.length; i++) {
                    path += this.breadcrumbs[i] + '/';
                }

                console.log(path + '/' + this.get('rename_txtfield'));

                var self = this;
                var xhttp = new XMLHttpRequest();
                xhttp.onreadystatechange = function () {
                    if (this.readyState == 4 && this.status == 200) {
                        //var data = JSON.parse(this.responseText);
                        self.set('loading', false);
                        self.send('startDownload');
                    }
                };
                xhttp.open("GET", '/fileserver/api/filemanager/rename.php?path=' + path + '&oldname=' + this.get('old_filename_temp') + '&newname=' + this.get('rename_txtfield'), true);

                console.log('/fileserver/api/filemanager/rename.php?path=' + path + '&oldname=' + this.get('old_filename_temp') + '&newname=' + this.get('rename_txtfield'));

                xhttp.send();
                self.set('loading', true);
            }

        }

    });
});