define('editor-burgendatenbank/controllers/map', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Controller.extend({

        geolocation: _ember['default'].inject.service(),
        initCenter: [49.049360, 11.286090],
        zoom: 7,
        minZoom: 5,
        maxZoom: 17,

        accuracy: 50,

        basemaps: [],
        layers: [],

        hist: false,

        altmuehl_json: [],
        altmuehl_json_backup: [],

        basemap: [],

        greenIcon: [],

        greenMarker: [],
        orangeMarker: [],
        redMarker: [],
        greyMarker: [],

        init: function init() {
            var self = this;
            this.updateMapCenter(this.initCenter[0], this.initCenter[1]);

            this.greenIcon = L.icon({
                iconUrl: 'http://leafletjs.com/examples/custom-icons/leaf-green.png',
                shadowUrl: 'leaf-shadow.png',

                iconSize: [38, 95], // size of the icon
                shadowSize: [50, 64], // size of the shadow
                iconAnchor: [22, 94], // point of the icon which will correspond to marker's location
                shadowAnchor: [4, 62], // the same for the shadow
                popupAnchor: [-3, -76] // point from which the popup should open relative to the iconAnchor
            });

            this.redMarker = L.ExtraMarkers.icon({
                icon: 'fa-fort-awesome',
                markerColor: 'red',
                shape: 'circle',
                prefix: 'fa'
            });

            this.greenMarker = L.ExtraMarkers.icon({
                icon: 'fa-fort-awesome',
                markerColor: 'green-light',
                shape: 'circle',
                prefix: 'fa'
            });

            this.orangeMarker = L.ExtraMarkers.icon({
                icon: 'fa-fort-awesome',
                markerColor: 'orange',
                shape: 'circle',
                prefix: 'fa'
            });

            this.greyMarker = L.ExtraMarkers.icon({
                icon: 'fa-fort-awesome',
                markerColor: 'black',
                shape: 'circle',
                prefix: 'fa'
            });

            var layer = {};
            layer.name = 'Straßenkarte (cartocdn)';
            layer.url = 'http://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png';
            layer.attribution = '';
            this.basemaps.addObject(layer);

            layer = {};
            layer.name = 'Straßenkarte (OpenStreetMap)';
            layer.url = 'http://{s}.tile.openstreetmap.de/tiles/osmde/{z}/{x}/{y}.png';
            layer.attribution = 'Map data: &copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>, <a href="http://viewfinderpanoramas.org">SRTM</a> | Map style: &copy; <a href="https://opentopomap.org">OpenTopoMap</a> (<a href="https://creativecommons.org/licenses/by-sa/3.0/">CC-BY-SA</a>)';
            this.basemaps.addObject(layer);

            layer = {};
            layer.name = 'Satellitenkarten (ArcGIS)';
            layer.url = 'http://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}';
            layer.attribution = 'Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community';
            this.basemaps.addObject(layer);

            layer = {};
            layer.name = 'Topographische Karte (OpenTopoMap)';
            layer.url = 'http://{s}.tile.opentopomap.org/{z}/{x}/{y}.png';
            layer.attribution = 'Kartendaten: © OpenStreetMap-Mitwirkende, SRTM | Kartendarstellung: © OpenTopoMap (CC-BY-SA)';
            this.basemaps.addObject(layer);

            layer = {};
            layer.name = 'Piratenkarte';
            layer.url = 'https://{s}.tiles.mapbox.com/v4/mapbox.pirates/{z}/{x}/{y}.png?access_token=pk.eyJ1IjoibWFwYm94IiwiYSI6ImNpejY4NDg1bDA1cjYzM280NHJ5NzlvNDMifQ.d6e-nNyBDtmQCVwVNivz7A';
            layer.attribution = '';
            this.basemaps.addObject(layer);

            layer = {};
            layer.name = '3D';
            layer.url = 'http://{s}.tiles.osm2world.org/osm/pngtiles/n/{z}/{x}/{y}.png';
            layer.attribution = '';
            this.basemaps.addObject(layer);

            this.basemap = this.basemaps[0];

            var layer = {};
            layer.objref = {};

            var xhttp = new XMLHttpRequest();
            xhttp.onreadystatechange = function () {
                if (this.readyState == 4 && this.status == 200) {
                    var data = JSON.parse(this.responseText);

                    console.log(JSON.parse(this.responseText));

                    self.set('altmuehl_json', JSON.parse(this.responseText));
                    self.set('altmuehl_json_backup', JSON.parse(this.responseText));

                    //self.set('field_wgs84_lat', data.results[0].geometry.location.lat);
                    //self.set('field_wgs84_lon', data.results[0].geometry.location.lng);
                }
            };

            //http://api.burgen.kdwt.manuel-hunziker.de/map/json_altmuehl_gesamt.php
            xhttp.open("GET", "http://api.burgen.kdwt.manuel-hunziker.de/map/map_burgen.php", true);

            xhttp.send();
        },

        updateMapCenter: function updateMapCenter(lat, lng) {
            this.set('lat', lat);
            this.set('lng', lng);
        },

        zoomToPos: function zoomToPos(lat, lng, zoomlevel) {
            this.set('lat', lat);
            this.set('lng', lng);
            this.set('zoom', zoomlevel);
        },

        actions: {

            updateMapView: function updateMapView(e) {
                console.log(e.target._zoom);
                this.set('zoom', e.target._zoom);
            },

            resetView: function resetView() {
                console.log("resetView");
                this.zoomToPos(49.049360, 11.286090, 7);
            },

            zoomIn: function zoomIn() {
                if (this.get('zoom') < this.get('maxZoom')) {
                    this.set('zoom', this.get('zoom') + 1);
                }
            },

            zoomOut: function zoomOut() {
                if (this.get('zoom') > this.get('minZoom')) {
                    this.set('zoom', this.get('zoom') - 1);
                }
            },

            getUserLocation: function getUserLocation() {
                var self = this;

                this.get('geolocation').getLocation().then(function (geoObject) {
                    var currentLocation = self.get('geolocation').get('currentLocation');
                    console.log(geoObject);

                    self.set('accuracy', geoObject.coords.accuracy);
                    self.updateMapCenter(currentLocation[0], currentLocation[1]);

                    self.set('userLocation', currentLocation);
                });
            },

            showViewManager: function showViewManager() {
                if (this.get("viewmanager")) {
                    this.set("viewmanager", false);
                    console.log(this.get("viewmanager"));
                } else {
                    this.set("viewmanager", true);
                    console.log(this.get("viewmanager"));
                }
            },

            setReste: function setReste(k) {
                var self = this;
                var temp = this.get('altmuehl_json_backup');
                var newArray = [];

                for (var i = 0; i < temp.length; i++) {
                    if (temp[i].Zustand == k || k == 'reset') {
                        newArray.addObject(temp[i]);
                    }
                }

                self.set('altmuehl_json', newArray);
            },

            setBasemap: function setBasemap(layer) {
                this.set('basemap', layer);
            },

            toggleBody: function toggleBody() {
                this.toggleProperty('isShowingBody');
            },
            toggleOpenTopo: function toggleOpenTopo() {
                this.toggleProperty('opentopo');
            }
        }
    });

    /*
    
    {{tile-layer url="https://intergeo16.bayernwolke.de/betty/eurogeo/{z}/{x}/{y}"}}
    
    {{tile-layer url="https://intergeo16.bayernwolke.de/betty/satdop40_komplett/{z}/{x}/{y}"}}
    
    {{tile-layer url="https://intergeo10.bayernwolke.de/betty/c_default/{z}/{x}/{y}"}}
    
    {{tile-layer url="https://intergeo16.bayernwolke.de/betty/atkis/{z}/{x}/{y}"}}
    
    {{tile-layer url="https://intergeo11.bayernwolke.de/betty/atkis_gray/{z}/{x}/{y}"}}
    
    {{tile-layer url="https://intergeo16.bayernwolke.de/betty/c_hist/{z}/{x}/{y}"}}
    
    
    https://gis.bavarikon.de/arcgis/services/HistorischeKarten/bsb00014450/MapServer/WMSServer?||0
    
    https://gis.bavarikon.de/arcgis/services/HistorischeKarten/bsb00014450/MapServer/WMSServer?||1
    */
});