define('editor-burgendatenbank/components/burg-detail', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Component.extend({

        downloadmanager: _ember['default'].inject.service('download-manager'),

        loading: true,

        group_attachment: [],

        field_publicationTFArray: [],

        publisher: [],
        creator: [],

        chronology: [],

        field_wgs84_lat: 0.0,
        field_wgs84_lon: 0.0,
        zoom: 16,

        init: function init() {

            this.set('loading', true);

            this._super();
        },

        updateQueryParams: (function () {
            console.log(this.url);
            this.get('downloadmanager').init(this.url + '?' + Math.random() * 100, this);
        }).observes('url'),

        actions: {

            updateLocation: function updateLocation(e) {
                var self = this;
                var location = e.target.getLatLng();
                self.set('field_wgs84_lat', location.lat);
                self.set('field_wgs84_lon', location.lng);
            },

            downloadDone: function downloadDone(xml) {
                this.set('response_xmlfile', xml);
                console.log($(xml));
                this.set('page_title_sync', $(xml).find("header").find("label").find("prefLabel").text());

                this.set('state', $(xml).find("header").find("location").find("state").text());
                this.set('federal_state', $(xml).find("header").find("location").find("federal-state").text());
                this.set('region', $(xml).find("header").find("location").find("region").text());
                this.set('county', $(xml).find("header").find("location").find("county").text());
                this.set('place', $(xml).find("header").find("location").find("place").text());
                this.set('district', $(xml).find("header").find("location").find("district").text());

                if ($(xml).find("header").find("location").find("geolocation").attr("show")) {
                    this.set('bool_showGeo', $(xml).find("header").find("location").find("geolocation").attr("show"));
                }

                if ($(xml).find("header").find("location").find("geolocation").find("WGS84").attr("lat")) {
                    this.set('field_wgs84_lat', $(xml).find("header").find("location").find("geolocation").find("WGS84").attr("lat"));
                }

                if ($(xml).find("header").find("location").find("geolocation").find("WGS84").attr("lon")) {
                    this.set('field_wgs84_lon', $(xml).find("header").find("location").find("geolocation").find("WGS84").attr("lon"));
                }

                for (var i = 0; i < $(xml).find("short-descs").find("short-desc").length; i++) {
                    var _sd = $(xml).find("short-descs").find("short-desc")[i];

                    if ($(_sd).attr("class") == 'Geschichte') {
                        this.set('short-descs_geschichte', $(_sd).text());
                    } else if ($(_sd).attr("class") == 'Bauentwicklung') {
                        this.set('short-descs_bauentwicklung', $(_sd).text());
                    } else if ($(_sd).attr("class") == 'Beschreibung') {
                        this.set('short-descs_beschreibung', $(_sd).text());
                    }
                }

                for (var i = 0; i < $(xml).find("attachments").find("attachment").length; i++) {
                    var _attachment = $(xml).find("attachments").find("attachment")[i];

                    this.group_attachment.addObject({
                        uri: $(_attachment).attr("uri"),
                        web_link: $(_attachment).attr("web-link"),
                        mime_type: $(_attachment).attr("mime-type"),
                        show: $(_attachment).attr("show"),
                        legend: $(_attachment).find("legend").text(),
                        creator: $(_attachment).find("creator").text(),
                        year: $(_attachment).find("year").text(),
                        licence: $(_attachment).find("licence").text()
                    });
                }

                for (var i = 0; i < $(xml).find("literature").find("publication").length; i++) {
                    this.field_publicationTFArray.addObject({
                        name: $(xml).find("literature").find("publication")[i].innerText
                    });
                }

                for (var i = 0; i < $(xml).find("header").find("edition").find("publisher").length; i++) {
                    this.publisher.addObject($(xml).find("header").find("edition").find("publisher")[i].innerText);
                }

                for (var i = 0; i < $(xml).find("header").find("edition").find("creator").length; i++) {
                    this.creator.addObject($(xml).find("header").find("edition").find("creator")[i].innerText);
                }

                this.set('date', $(xml).find("header").find("edition").find("date").text());

                for (var i = 0; i < $(xml).find("chronology").find("event").length; i++) {

                    var _event = $(xml).find("chronology").find("event")[i];

                    var baulich = false;
                    var historisch = false;
                    var type = [];
                    for (var k = 0; k < $(_event).find("type").length; k++) {
                        var _type = $(_event).find("type")[k];

                        if ($(_type).attr("class") == 'baulich') {
                            baulich = true;
                        } else if ($(_type).attr("class") == 'historisch') {
                            historisch = true;
                        }

                        type.addObject({
                            'class': {
                                label: $(_type).attr("class")
                            },
                            txt: {
                                label: $(_type).text()
                            }
                        });
                    }

                    var source = [];
                    for (var k = 0; k < $(_event).find("source").length; k++) {
                        var _source = $(_event).find("source")[k];

                        source.addObject({
                            'class': {
                                label: $(_source).attr("class")
                            },
                            type: {
                                label: $(_source).attr("type")
                            },
                            quality: {
                                label: $(_source).attr("quality")
                            }
                        });
                    }

                    var _date_begin = $(_event).find("date")[0];

                    var begin_certainty = {
                        label: $(_date_begin).attr("certainty")
                    };

                    var begin_focus = {
                        label: $(_date_begin).attr("focus")
                    };

                    var _date_end = $(_event).find("date")[1];

                    var end_certainty = {
                        label: $(_date_end).attr("certainty")
                    };

                    var end_focus = {
                        label: $(_date_end).attr("focus")
                    };

                    var res = $(_event).attr("phasenr").split("-");
                    if (parseInt(res[3]) > this.get('phasenr_max')) {
                        this.set('phasenr_max', parseInt(res[3]));
                    }

                    this.chronology.addObject({
                        phasenr: $(_event).attr("phasenr"),
                        collapseID: res[1] + '-' + res[2] + '-' + res[3],
                        type: type,
                        baulich: baulich,
                        historisch: historisch,
                        begin: $(_date_begin).attr("when"),
                        begin_certainty: begin_certainty,
                        begin_focus: begin_focus,
                        end: $(_date_end).attr("when"),
                        end_certainty: end_certainty,
                        end_focus: end_focus,
                        comment: $(_event).find("comment").text(),
                        source: source
                    });
                }

                this.set('loading', false);
            }
        }

    });
});