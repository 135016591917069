define("editor-burgendatenbank/components/editor-refs", ["exports", "ember", "npm:xmlbuilder"], function (exports, _ember, _npmXmlbuilder) {
    exports["default"] = _ember["default"].Component.extend({
        group_objref_phaseref: [],
        group_familyref_phaseref: [],

        sync_objref_list_convert: [],

        temp_phaseref_list: [],

        init: function init() {

            this._super();
            this.group_objref_phaseref.clear();
            this.group_familyref_phaseref.clear();

            this.generateObjectList();

            if (this.input.length > 0) {
                var xml = this.input;

                for (var i = 0; i < $(xml).find("refs").find("ref").length; i++) {
                    var _ref = $(xml).find("refs").find("ref")[i];

                    if ($(_ref).attr("objref") && $(_ref).attr("phaseref")) {
                        var _object = {};

                        _object.objref = {};
                        _object.objref.invnr = $(_ref).attr("objref");
                        _object.objref["class"] = '';
                        _object.objref.type = '';
                        _object.objref.feature = '';
                        _object.objref.level = 0;
                        _object.objref.status = true;

                        for (var j = 0; j < this.sync_objref_list_convert.length; j++) {

                            if (this.sync_objref_list_convert[j].invnr == $(_ref).attr("objref")) {
                                _object.objref["class"] = this.sync_objref_list_convert[j]["class"];
                                _object.objref.type = this.sync_objref_list_convert[j].type;
                                _object.objref.level = this.sync_objref_list_convert[j].level;
                                _object.objref.feature = this.sync_objref_list_convert[j].feature;

                                console.log;
                            }
                        }

                        for (var j = 0; j < this.sync_phaseref_list.length; j++) {
                            if (this.sync_phaseref_list[j].phasenr == $(_ref).attr("phaseref")) {
                                _object.phaseref = this.sync_phaseref_list[j];
                                _object.phaseref.status = true;
                            }
                        }

                        this.group_objref_phaseref.addObject(_object);
                    } else if ($(_ref).attr("familyref") && $(_ref).attr("phaseref")) {
                        var _object = {};

                        for (var j = 0; j < this.sync_familyref_list.length; j++) {
                            if (this.sync_familyref_list[j].familynr == $(_ref).attr("familyref")) {
                                _object.familyref = this.sync_familyref_list[j];
                                _object.familyref.status = true;
                            }
                        }

                        for (var j = 0; j < this.sync_phaseref_list.length; j++) {
                            if (this.sync_phaseref_list[j].phasenr == $(_ref).attr("phaseref")) {
                                _object.phaseref = this.sync_phaseref_list[j];
                                _object.phaseref.status = true;
                            }
                        }

                        this.group_familyref_phaseref.addObject(_object);
                    }
                }
            }
        },

        genObjectListChildren: function genObjectListChildren(_element, _level) {
            var _type = '';

            for (var k = 0; k < _element.type.length; k++) {
                _type += _element.type[k].label;

                if (k < _element.type.length - 1) {
                    _type += ',';
                }
            }

            var _class = '';

            for (var k = 0; k < _element["class"].length; k++) {
                _class += _element["class"][k].label;

                if (k < _element["class"].length - 1) {
                    _class += ',';
                }
            }

            var _feature = '';

            for (var k = 0; k < _element.feature.length; k++) {
                _feature += _element.feature[k].label;

                if (k < _element.feature.length - 1) {
                    _feature += ',';
                }
            }

            this.sync_objref_list_convert.addObject({
                invnr: _element.invnr,
                "class": _class,
                type: _type,
                feature: _feature,
                level: _level
            });

            console.log(_element);

            for (var i = 0; i < _element.children.length; i++) {
                this.genObjectListChildren($(_element.children)[i], _level + 1);
            }
        },

        generateObjectList: (function () {
            this.set('sync_objref_list_convert', []);

            console.log('run generateObjectList');

            for (var i = 0; i < this.sync_objref_list.length; i++) {
                this.genObjectListChildren($(this.sync_objref_list)[i], 0);
            }
        }).observes('sync_objref_list'),

        // =========================================
        // =========================================

        generate_xml: (function () {
            if (this.group_objref_phaseref.length > 0 || this.group_familyref_phaseref.length > 0) {
                var xml = _npmXmlbuilder["default"].create('refs');

                for (var i = 0; i < this.group_objref_phaseref.length; i++) {
                    var _ref = xml.ele('ref');

                    if (this.group_objref_phaseref[i].objref) {
                        _ref.att('objref', this.group_objref_phaseref[i].objref.invnr);
                    }

                    if (this.group_objref_phaseref[i].phaseref) {
                        _ref.att('phaseref', this.group_objref_phaseref[i].phaseref.phasenr);
                    }
                }

                for (var i = 0; i < this.group_familyref_phaseref.length; i++) {
                    var _ref = xml.ele('ref');

                    if (this.group_familyref_phaseref[i].familyref) {
                        _ref.att('familyref', this.group_familyref_phaseref[i].familyref.familynr);
                    }

                    if (this.group_familyref_phaseref[i].phaseref) {
                        _ref.att('phaseref', this.group_familyref_phaseref[i].phaseref.phasenr);
                    }
                }

                this.sendAction('returnXMLString', xml);
            } else {
                this.sendAction('returnXMLString', '');
            }
        }).observes('runXMLGenerator'),
        // =========================================
        // =========================================

        focusIn: function focusIn() {
            //this.generateObjectList();
        },

        actions: {
            add_group_objref_phaseref: function add_group_objref_phaseref() {
                this.group_objref_phaseref.addObject({
                    objref: '',
                    phaseref: ''
                });
            },
            remove_group_objref_phaseref: function remove_group_objref_phaseref(index) {
                this.group_objref_phaseref.removeAt(index);
            },

            add_group_familyref_phaseref: function add_group_familyref_phaseref() {
                this.group_familyref_phaseref.addObject({
                    familyref: '',
                    phaseref: ''
                });
            },
            remove_group_familyref_phaseref: function remove_group_familyref_phaseref(index) {
                this.group_familyref_phaseref.removeAt(index);
            },
            updateObjectRefList: function updateObjectRefList() {
                this.generateObjectList();
            },

            updatePhaseDropDown: function updatePhaseDropDown(_typ) {
                this.set('temp_phaseref_list', []);

                var temp_sync_phaseref_list = this.get('sync_phaseref_list');

                var temp = [];

                for (var j = 0; j < temp_sync_phaseref_list.length; j++) {
                    for (var m = 0; m < temp_sync_phaseref_list[j].type.length; m++) {
                        if (temp_sync_phaseref_list[j].type[m]["class"].label == _typ) {
                            temp.addObject(temp_sync_phaseref_list[j]);
                            console.log(temp_sync_phaseref_list[j].type[m]["class"].label);
                        }
                    }
                }

                this.set('temp_phaseref_list', temp);
            },

            check: function check() {
                this.generateObjectList();

                var error_count = 0;

                for (var i = 0; i < this.group_objref_phaseref.length; i++) {
                    var temp = this.get('group_objref_phaseref').objectAt(i);

                    _ember["default"].set(temp, "objref.status", false);
                    error_count++;
                    for (var j = 0; j < this.sync_objref_list_convert.length; j++) {
                        if (this.sync_objref_list_convert[j].invnr == temp.objref.invnr) {
                            _ember["default"].set(temp, "objref.status", true);
                            error_count--;
                            j = this.sync_objref_list_convert.length - 1;
                        }
                    }

                    _ember["default"].set(temp, "phaseref.status", false);
                    error_count++;
                    for (var j = 0; j < this.sync_phaseref_list.length; j++) {
                        if (this.sync_phaseref_list[j].phasenr == temp.phaseref.phasenr) {
                            _ember["default"].set(temp, "phaseref.status", true);
                            error_count--;
                            j = this.sync_phaseref_list.length - 1;
                        }
                    }
                }

                for (var i = 0; i < this.group_familyref_phaseref.length; i++) {
                    var temp = this.get('group_familyref_phaseref').objectAt(i);

                    _ember["default"].set(temp, "familyref.status", false);
                    error_count++;
                    for (var j = 0; j < this.sync_familyref_list.length; j++) {
                        if (this.sync_familyref_list[j].familynr == temp.familyref.familynr) {
                            _ember["default"].set(temp, "familyref.status", true);
                            error_count--;
                            j = this.sync_familyref_list.length - 1;
                        }
                    }

                    _ember["default"].set(temp, "phaseref.status", false);
                    error_count++;
                    for (var j = 0; j < this.sync_phaseref_list.length; j++) {
                        if (this.sync_phaseref_list[j].phasenr == temp.phaseref.phasenr) {
                            _ember["default"].set(temp, "phaseref.status", true);
                            error_count--;
                            j = this.sync_phaseref_list.length - 1;
                        }
                    }
                }

                if (error_count > 0) {
                    this.toast.error('Es wurden ' + error_count + ' fehlerhaften Referenzen gefunden. :-(');
                } else {
                    this.toast.success('Keine fehlerhaften Referenzen gefunden. :-) Juhu!');
                }
            }
        }

    });
});