define('editor-burgendatenbank/router', ['exports', 'ember', 'editor-burgendatenbank/config/environment'], function (exports, _ember, _editorBurgendatenbankConfigEnvironment) {

  var Router = _ember['default'].Router.extend({
    location: _editorBurgendatenbankConfigEnvironment['default'].locationType,
    rootURL: _editorBurgendatenbankConfigEnvironment['default'].rootURL
  });

  Router.map(function () {
    this.route('login');
    this.route('dashboard');
    this.route('create');
    this.route('filebrowser');
    this.route('editor');
    this.route('export');
    this.route('admin');
    this.route('vocab');
    this.route('map');
    this.route('burg');
  });

  exports['default'] = Router;
});