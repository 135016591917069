define('editor-burgendatenbank/controllers/index', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Controller.extend({
        altmuehl_json: [],

        region: [],

        init: function init() {

            var self = this;

            var xhttp = new XMLHttpRequest();
            xhttp.onreadystatechange = function () {
                if (this.readyState == 4 && this.status == 200) {
                    var data = JSON.parse(this.responseText);

                    console.log(JSON.parse(this.responseText));

                    self.set('altmuehl_json', JSON.parse(this.responseText));
                    self.set('altmuehl_json_backup', JSON.parse(this.responseText));

                    //self.set('field_wgs84_lat', data.results[0].geometry.location.lat);
                    //self.set('field_wgs84_lon', data.results[0].geometry.location.lng);
                }
            };

            //http://api.burgen.kdwt.manuel-hunziker.de/map/json_altmuehl_gesamt.php
            xhttp.open("GET", "http://api.burgen.kdwt.manuel-hunziker.de/map/map_burgen.php", true);

            xhttp.send();

            xhttp = new XMLHttpRequest();
            xhttp.onreadystatechange = function () {
                if (this.readyState == 4 && this.status == 200) {
                    var data = JSON.parse(this.responseText);

                    console.log(JSON.parse(this.responseText));

                    self.set('region', JSON.parse(this.responseText));

                    //self.set('field_wgs84_lat', data.results[0].geometry.location.lat);
                    //self.set('field_wgs84_lon', data.results[0].geometry.location.lng);
                }
            };

            //http://api.burgen.kdwt.manuel-hunziker.de/map/json_altmuehl_gesamt.php
            xhttp.open("GET", "/fileserver/api/vocabulary/getVocab.php?group=header&target-element=region", true);

            xhttp.send();
        }

    });
});