define('editor-burgendatenbank/services/download-manager', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Service.extend({
        _response: '',

        init: function init(_url, _controller) {
            var self = this;
            var xhttp = new XMLHttpRequest();
            xhttp.onreadystatechange = function () {
                if (this.readyState == 4 && this.status == 200) {
                    self.set('_response', this.responseText);
                    _controller.send('downloadDone', this.responseText);
                }
            };
            xhttp.open("GET", _url, true);
            xhttp.send();
        }
    });
});