define('editor-burgendatenbank/controllers/login', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Controller.extend({
        authmanager: _ember['default'].inject.service('auth-manager'),
        toast: _ember['default'].inject.service(),

        user_list: [{
            name: 'Tobias Arera-Rütenik'
        }, {
            name: 'Manuel Hunziker'
        }, {
            name: 'Volker Rößner'
        }, {
            name: 'Lisa Bleck'
        }, {
            name: 'Clara Forcht'
        }, {
            name: 'Simon Schmidt'
        }],
        user_selected: '',

        bool_remember: false,

        actions: {
            login: function login() {
                if (this.get('user_selected')) {
                    if (this.get('authmanager').login(this.get('user_selected').name, '')) {
                        window.location.reload(true);
                    }
                } else {
                    this.toast.warning('Bitte wählen Sie einen Benutzer aus.');
                }
            }
        }
    });
});