define('editor-burgendatenbank/app', ['exports', 'ember', 'editor-burgendatenbank/resolver', 'ember-load-initializers', 'editor-burgendatenbank/config/environment'], function (exports, _ember, _editorBurgendatenbankResolver, _emberLoadInitializers, _editorBurgendatenbankConfigEnvironment) {

  var App = undefined;

  _ember['default'].MODEL_FACTORY_INJECTIONS = true;

  App = _ember['default'].Application.extend({
    modulePrefix: _editorBurgendatenbankConfigEnvironment['default'].modulePrefix,
    podModulePrefix: _editorBurgendatenbankConfigEnvironment['default'].podModulePrefix,
    Resolver: _editorBurgendatenbankResolver['default']
  });

  (0, _emberLoadInitializers['default'])(App, _editorBurgendatenbankConfigEnvironment['default'].modulePrefix);

  exports['default'] = App;
});