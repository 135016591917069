define('editor-burgendatenbank/services/page-title-list', ['exports', 'ember-page-title/services/page-title-list', 'editor-burgendatenbank/config/environment'], function (exports, _emberPageTitleServicesPageTitleList, _editorBurgendatenbankConfigEnvironment) {

  function capitalize(key) {
    return key.charAt(0).toUpperCase() + key.slice(1);
  }

  var defaults = {};
  ['separator', 'prepend', 'replace'].forEach(function (key) {
    if (_editorBurgendatenbankConfigEnvironment['default'].pageTitle && _editorBurgendatenbankConfigEnvironment['default'].pageTitle[key]) {
      defaults['default' + capitalize(key)] = _editorBurgendatenbankConfigEnvironment['default'].pageTitle[key];
    }
  });

  exports['default'] = _emberPageTitleServicesPageTitleList['default'].extend(defaults);
});