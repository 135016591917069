define('editor-burgendatenbank/components/editor-history-event', ['exports', 'ember', 'npm:xmlbuilder'], function (exports, _ember, _npmXmlbuilder) {
    exports['default'] = _ember['default'].Component.extend({
        component_init: false,
        phasenr_max: 0,

        sync_phaseref_list: [],

        init: function init() {
            this._super();
            this.sync_phaseref_list.clear();

            console.log('history-event has rendered!');
            if (this.input.length > 0) {
                var xml = this.input;

                //console.log(xml);

                console.log($(xml).find("chronology").find("event"));

                for (var i = 0; i < $(xml).find("chronology").find("event").length; i++) {

                    var _event = $(xml).find("chronology").find("event")[i];

                    var type = [];
                    for (var k = 0; k < $(_event).find("type").length; k++) {
                        var _type = $(_event).find("type")[k];

                        type.addObject({
                            'class': {
                                label: $(_type).attr("class")
                            },
                            txt: {
                                label: $(_type).text()
                            }
                        });
                    }

                    var source = [];
                    for (var k = 0; k < $(_event).find("source").length; k++) {
                        var _source = $(_event).find("source")[k];

                        source.addObject({
                            'class': {
                                label: $(_source).attr("class")
                            },
                            type: {
                                label: $(_source).attr("type")
                            },
                            quality: {
                                label: $(_source).attr("quality")
                            }
                        });
                    }

                    var _date_begin = $(_event).find("date")[0];

                    var begin_certainty = {
                        label: $(_date_begin).attr("certainty")
                    };

                    var begin_focus = {
                        label: $(_date_begin).attr("focus")
                    };

                    var _date_end = $(_event).find("date")[1];

                    var end_certainty = {
                        label: $(_date_end).attr("certainty")
                    };

                    var end_focus = {
                        label: $(_date_end).attr("focus")
                    };

                    var res = $(_event).attr("phasenr").split("-");
                    if (parseInt(res[3]) > this.get('phasenr_max')) {
                        this.set('phasenr_max', parseInt(res[3]));
                    }

                    this.sync_phaseref_list.addObject({
                        phasenr: $(_event).attr("phasenr"),
                        collapseID: res[1] + '-' + res[2] + '-' + res[3],
                        type: type,
                        begin: $(_date_begin).attr("when"),
                        begin_certainty: begin_certainty,
                        begin_focus: begin_focus,
                        end: $(_date_end).attr("when"),
                        end_certainty: end_certainty,
                        end_focus: end_focus,
                        comment: $(_event).find("comment").text(),
                        source: source
                    });
                }
            }
        },

        vocab_type_class_list: [],
        vocab_type_txt_list: [],

        vocab_date_certainty_list: [],
        vocab_date_focus_list: [],

        certainty_list: [],
        focus_list: [],

        vocab_source_class_list: [],
        vocab_source_type_list: [],
        vocab_source_quality_list: [],

        downloadvocab: false,

        focusIn: function focusIn() {
            if (!this.downloadvocab) {

                this.set('vocab_type_class_list', fetch('/fileserver/api/vocabulary/getVocab.php?group=chronology&target-element=type&target-attribute=class&when-class=null').then(function (response) {
                    return response.json();
                }));

                this.set('certainty_list', fetch('/fileserver/api/vocabulary/getVocab.php?group=chronology&target-element=date&target-attribute=certainty').then(function (response) {
                    return response.json();
                }));

                this.set('focus_list', fetch('/fileserver/api/vocabulary/getVocab.php?group=chronology&target-element=date&target-attribute=focus').then(function (response) {
                    return response.json();
                }));

                this.set('vocab_source_class_list', fetch('/fileserver/api/vocabulary/getVocab.php?group=chronology&target-element=source&target-attribute=class').then(function (response) {
                    return response.json();
                }));

                /*this.set('vocab_source_type_list', fetch('/fileserver/api/vocabulary/getVocab.php?group=chronology&target-element=source&target-attribute=type&when-class=' + ).then(function (response) {
                    return response.json();
                 }));*/

                this.set('vocab_source_quality_list', fetch('/fileserver/api/vocabulary/getVocab.php?group=chronology&target-element=source&target-attribute=quality').then(function (response) {
                    return response.json();
                }));

                this.downloadvocab = true;
            }
        },

        // =========================================
        // =========================================

        generate_xml: (function () {
            if (this.sync_phaseref_list.length > 0) {
                var xml = _npmXmlbuilder['default'].create('chronology');

                for (var i = 0; i < this.sync_phaseref_list.length; i++) {
                    var _event = xml.ele('event');

                    if (this.sync_phaseref_list[i].phasenr) {
                        _event.att('phasenr', this.sync_phaseref_list[i].phasenr);
                    }

                    for (var s = 0; s < this.sync_phaseref_list[i].type.length; s++) {
                        var _type = _event.ele('type');

                        if (this.sync_phaseref_list[i].type[s]['class']) {
                            if (this.sync_phaseref_list[i].type[s]['class'].label) {
                                _type.att('class', this.sync_phaseref_list[i].type[s]['class'].label);
                            }
                        }

                        if (this.sync_phaseref_list[i].type[s].txt) {
                            if (this.sync_phaseref_list[i].type[s].txt.label) {
                                _type.txt(this.sync_phaseref_list[i].type[s].txt.label);
                            }
                        }
                    }

                    var date = _event.ele('date');

                    if (this.sync_phaseref_list[i].begin) {
                        date.att('when', this.sync_phaseref_list[i].begin);
                    }

                    if (this.sync_phaseref_list[i].begin_certainty) {
                        if (this.sync_phaseref_list[i].begin_certainty.label) {
                            date.att('certainty', this.sync_phaseref_list[i].begin_certainty.label);
                        }
                    }

                    if (this.sync_phaseref_list[i].begin_focus) {
                        if (this.sync_phaseref_list[i].begin_focus.label) {
                            date.att('focus', this.sync_phaseref_list[i].begin_focus.label);
                        }
                    }

                    if (this.sync_phaseref_list[i].end) {
                        date = _event.ele('date');
                        date.att('when', this.sync_phaseref_list[i].end);

                        if (this.sync_phaseref_list[i].end_certainty) {
                            if (this.sync_phaseref_list[i].end_certainty.label) {
                                date.att('certainty', this.sync_phaseref_list[i].end_certainty.label);
                            }
                        }

                        if (this.sync_phaseref_list[i].end_focus) {
                            if (this.sync_phaseref_list[i].end_focus.label) {
                                date.att('focus', this.sync_phaseref_list[i].end_focus.label);
                            }
                        }
                    }

                    if (this.sync_phaseref_list[i].comment) {
                        _event.ele('comment').txt(this.sync_phaseref_list[i].comment);
                    }

                    for (var s = 0; s < this.sync_phaseref_list[i].source.length; s++) {
                        var _source = _event.ele('source');

                        if (this.sync_phaseref_list[i].source[s]['class']) {
                            if (this.sync_phaseref_list[i].source[s]['class'].label) {
                                _source.att('class', this.sync_phaseref_list[i].source[s]['class'].label);
                            }
                        }

                        if (this.sync_phaseref_list[i].source[s].type) {
                            if (this.sync_phaseref_list[i].source[s].type.label) {
                                _source.att('type', this.sync_phaseref_list[i].source[s].type.label);
                            }
                        }

                        if (this.sync_phaseref_list[i].source[s].quality) {
                            if (this.sync_phaseref_list[i].source[s].quality.label) {
                                _source.att('quality', this.sync_phaseref_list[i].source[s].quality.label);
                            }
                        }
                    }
                }
                this.sendAction('returnXMLString', xml);
            } else {
                this.sendAction('returnXMLString', '');
            }
        }).observes('runXMLGenerator'),

        // =========================================
        // =========================================

        actions: {
            add_event: function add_event() {
                this.phasenr_max += 1;
                var phasenr_string = '000' + this.phasenr_max;

                if (this.phasenr_max >= 10 && this.phasenr_max <= 99) {
                    phasenr_string = '00' + this.phasenr_max;
                } else if (this.phasenr_max >= 100 && this.phasenr_max <= 999) {
                    phasenr_string = '0' + this.phasenr_max;
                } else if (this.phasenr_max >= 1000) {
                    phasenr_string = this.phasenr_max;
                }

                var phase = {};
                phase.phasenr = this.county_abbr + '-p-' + this.county_id + '-' + phasenr_string;
                phase.collapseID = 'p-' + this.county_id + '-' + phasenr_string;
                phase.type = [];
                phase.source = [];

                this.sync_phaseref_list.addObject(phase);
            },
            remove_event: function remove_event(index) {
                this.sync_phaseref_list.removeAt(index);
            },

            chooseType: function chooseType(index, _c) {
                this.feature.removeAt(index);
                this.feature.splice(index, 0, _c);
            },

            add_group_type: function add_group_type(_typeArray) {
                _typeArray.addObject({});
            },
            remove_group_type: function remove_group_type(index, _typeArray) {
                _typeArray.removeAt(index);
            },

            add_source: function add_source(_sourceArray) {
                _sourceArray.addObject({});
            },
            remove_source: function remove_source(index, _sourceArray) {
                _sourceArray.removeAt(index);
            },

            sort: function sort() {
                this.sync_phaseref_list.sort(function (a, b) {
                    return parseFloat(a.begin) - parseFloat(b.begin);
                });

                this.sync_phaseref_list.addObject({});
                this.sync_phaseref_list.removeAt(this.sync_phaseref_list.length - 1);
            },

            updateTypeDetailList: function updateTypeDetailList(_class) {
                //console.log(_class.label);

                this.set('vocab_type_txt_list', []);

                if (_class) {
                    this.set('vocab_type_txt_list', fetch('/fileserver/api/vocabulary/getVocab.php?group=chronology&target-element=type&target-attribute=null&when-class=' + _class.label).then(function (response) {
                        return response.json();
                    }));
                } else {
                    this.set('vocab_type_txt_list', fetch('/fileserver/api/vocabulary/getVocab.php?group=chronology&target-element=type&target-attribute=null&when-class=').then(function (response) {
                        return response.json();
                    }));
                }
            },

            updateSourceTypeList: function updateSourceTypeList(_class) {
                //console.log(_class.label);

                this.set('vocab_source_type_list', []);

                if (_class) {
                    this.set('vocab_source_type_list', fetch('/fileserver/api/vocabulary/getVocab.php?group=chronology&target-element=source&target-attribute=type&when-class=' + _class.label).then(function (response) {
                        return response.json();
                    }));
                } else {
                    this.set('vocab_source_type_list', fetch('/fileserver/api/vocabulary/getVocab.php?group=chronology&target-element=source&target-attribute=type&when-class=').then(function (response) {
                        return response.json();
                    }));
                }
            }
        }

    });
});