define("editor-burgendatenbank/components/editor-attachements", ["exports", "ember", "npm:xmlbuilder"], function (exports, _ember, _npmXmlbuilder) {
    exports["default"] = _ember["default"].Component.extend({
        group_attachment: [],

        init: function init() {
            this._super();
            this.group_attachment.clear();

            if (this.input.length > 0) {
                var xml = this.input;

                for (var i = 0; i < $(xml).find("attachments").find("attachment").length; i++) {
                    var _attachment = $(xml).find("attachments").find("attachment")[i];

                    this.group_attachment.addObject({
                        uri: $(_attachment).attr("uri"),
                        web_link: $(_attachment).attr("web-link"),
                        mime_type: $(_attachment).attr("mime-type"),
                        show: $(_attachment).attr("show"),
                        legend: $(_attachment).find("legend").text(),
                        creator: $(_attachment).find("creator").text(),
                        year: $(_attachment).find("year").text(),
                        licence: $(_attachment).find("licence").text()
                    });
                }
            }
        },

        // =========================================
        // =========================================

        generate_xml: (function () {
            if (this.group_attachment.length > 0) {
                var xml = _npmXmlbuilder["default"].create('attachments');

                for (var i = 0; i < this.group_attachment.length; i++) {
                    if (this.group_attachment[i].uri) {
                        var _attachment = xml.ele('attachment');
                        _attachment.att('uri', this.group_attachment[i].uri);
                        _attachment.att('web-link', this.group_attachment[i].web_link);
                        _attachment.att('mime-type', this.group_attachment[i].mime_type);
                        _attachment.att('show', this.group_attachment[i].show);

                        _attachment.ele('legend').txt(this.group_attachment[i].legend);
                        _attachment.ele('creator').txt(this.group_attachment[i].creator);
                        _attachment.ele('year').txt(this.group_attachment[i].year);
                        _attachment.ele('licence').txt(this.group_attachment[i].licence);
                    }
                }
                this.sendAction('returnXMLString', xml);
            } else {
                this.sendAction('returnXMLString', '');
            }
        }).observes('runXMLGenerator'),
        // =========================================
        // =========================================

        actions: {
            add_group_attachment: function add_group_attachment() {
                this.group_attachment.addObject({});
            },
            remove_group_attachment: function remove_group_attachment(index) {
                this.group_attachment.removeAt(index);
            },
            upload: function upload(files, resetInput) {

                var self = this;
                console.log(files);

                if (files) {
                    console.log(files[0]);
                    var formData = new FormData();
                    formData.append('path', this.url.replace("/fileserver/files/", ""));
                    formData.append("datei", files[0]);

                    var request = new XMLHttpRequest();
                    request.open("POST", "/fileserver/api/filemanager/uploadAttachment.php");

                    request.onload = function (oEvent) {
                        if (request.status == 200) {

                            console.log(this.responseText);

                            var data = JSON.parse(this.responseText);
                            //console.log(data[0]["web-link"]);
                            self.set('loading', false);

                            if (data[0]["status"] == "ok") {
                                console.log("Done!");
                                self.toast.success('Datei erfolgreich hochgeladen.');

                                self.group_attachment.addObject({
                                    uri: data[0]["uri"],
                                    web_link: data[0]["web-link"],
                                    mime_type: data[0]["mime-type"],
                                    show: false,
                                    legend: '',
                                    creator: '',
                                    year: '',
                                    licence: ''
                                });
                            } else {
                                console.log("Done!");
                                self.toast.warning(data[0]["message"]);
                            }
                        } else {
                            self.set('loading', false);
                            self.toast.error('Datei konnte nicht erfolgreich hochgeladen werden.');
                            console.log("Error " + oReq.status + " occurred when trying to upload your file.");
                        }
                    };

                    request.send(formData);
                    self.set('loading', true);
                }

                // Do something with your files.
                // Once you're done, call the reset method:
                resetInput();
                // Now your input is reset!
            }
        }

    });
});