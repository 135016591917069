define('editor-burgendatenbank/components/input-valid', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Component.extend({
        init_input: true,
        required: true,
        error: false,
        value: '',
        min: 0,
        type: 'string',
        error_message: '',

        init: function init() {
            this._super();
            this.check();
        },

        check: (function () {
            var val = this.get('value');
            var typ = this.get('type');

            if (typ == 'int') {
                if (!val.includes(".") && !val.includes(",") && val.length > 0) {
                    this.set('error', false);
                } else {
                    this.set('error', true);
                    this.set('error_message', 'Bitte geben Sie eine Ganzzahl ein.');
                }
            } else if (typ == 'float') {
                if (val.includes(",") && !val.includes(".") && val.length > 0) {
                    this.set('error', false);
                } else {
                    this.set('error', true);
                    this.set('error_message', 'Bitte geben Sie eine Gleitkommazahl ein.');
                }
            } else if (typ == 'geo') {
                if ((val.includes(".") && !val.includes(",") || !val.includes(",")) && val.length > 0) {
                    this.set('error', false);
                } else {
                    this.set('error', true);
                    this.set('error_message', 'Bitte geben Sie einen gültigen Koordinatenwert ein');
                }
            } else if (val.length == 0) {
                this.set('error', true);
                this.set('error_message', 'Bitte füllen Sie das Feld aus.');
            } else if (val.length < this.get('min')) {
                this.set('error', true);
                this.set('error_message', 'Bitte geben Sie mindestens ' + this.get('min') + ' Zeichen ein.');
            } else {
                this.set('error', false);
            }
        }).observes('value')

    });
});