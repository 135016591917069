define('editor-burgendatenbank/components/editor-shortdescs', ['exports', 'ember', 'npm:xmlbuilder'], function (exports, _ember, _npmXmlbuilder) {
    exports['default'] = _ember['default'].Component.extend({
        familynr_max: 0,

        init: function init() {
            this._super();
            this.init_xml();
        },

        array_short_descs: [],
        short_descs_class_list: [],
        short_descs_class_select: '',

        // =========================================
        // =========================================

        downloadvocab: false,

        focusIn: function focusIn() {
            if (!this.downloadvocab) {
                this.init_vocab();
                this.downloadvocab = true;
            }
        },

        init_vocab: function init_vocab() {
            this.short_descs_class_list.clear();

            this.set('short_descs_class_list', fetch('/fileserver/api/vocabulary/getVocab.php?group=short-descs&target-element=short-desc&target-attribute=class').then(function (response) {
                return response.json();
            }));
        },

        // =========================================
        // =========================================
        init_xml: function init_xml() {
            if (this.input.length > 0) {
                var xml = this.input;

                this.array_short_descs.clear();

                for (var i = 0; i < $(xml).find("short-descs").find("short-desc").length; i++) {
                    var _sd = $(xml).find("short-descs").find("short-desc")[i];
                    this.array_short_descs.addObject({
                        txt: $(_sd).text(),
                        label: $(_sd).attr("class")
                    });
                }
            }
        },
        // =========================================
        // =========================================

        generate_xml: (function () {
            if (this.array_short_descs.length > 0) {
                var short_descs = _npmXmlbuilder['default'].create('short-descs');

                for (var i = 0; i < this.array_short_descs.length; i++) {
                    var _short_desc = short_descs.ele('short-desc');
                    _short_desc.att('class', this.array_short_descs[i].label);
                    _short_desc.txt(this.array_short_descs[i].txt);
                }

                this.sendAction('returnXMLString', short_descs);
            } else {
                this.sendAction('returnXMLString', '');
            }
        }).observes('runXMLGenerator'),
        // =========================================
        // =========================================

        _helperFindGesamtanlage: function _helperFindGesamtanlage(_array) {
            for (var a = 0; a < _array.length; a++) {
                for (var b = 0; b < _array[a].type.length; b++) {
                    if (_array[a].type[b].label == 'Gesamtanlage') {
                        return _array[a];
                    }
                }
            }

            return null;
        },

        _helperFindClassWithType: function _helperFindClassWithType(_array, _class, _type) {
            for (var a = 0; a < _array.length; a++) {
                for (var b = 0; b < _array[a]['class'].length; b++) {
                    if (_array[a]['class'][b].label == _class) {
                        for (var c = 0; c < _array[a].type.length; c++) {
                            if (_array[a].type[c].label == _type) {
                                return _array[a];
                            }
                        }
                    }
                }
            }

            return null;
        },

        _helperFindClass: function _helperFindClass(_parent, _searchString) {
            if (_parent) {
                for (var c = 0; c < _parent.children.length; c++) {
                    for (var d = 0; d < _parent.children[c]['class'].length; d++) {
                        if (_parent.children[c]['class'][d].label == _searchString) {
                            return _parent.children[c];
                        }
                    }
                }
            }

            return null;
        },

        _helperFindType: function _helperFindType(_parent, _searchString) {
            for (var c = 0; c < _parent.children.length; c++) {
                for (var d = 0; d < _parent.children[c].type.length; d++) {
                    if (_parent.children[c].type[d].label == _searchString) {
                        return _parent.children[c];
                    }
                }
            }

            return null;
        },

        _getFeature: function _getFeature(_element) {
            var txt = '';

            for (var a = 0; a < _element.feature.length; a++) {
                txt += _element.feature[a].label;

                if (a < _element.feature.length - 1) {
                    txt += ', ';
                }
            }

            return txt;
        },

        _getType: function _getType(_element) {
            var txt = '';

            for (var a = 0; a < _element.type.length; a++) {
                txt += _element.type[a].label;

                if (a < _element.type.length - 1) {
                    txt += ', ';
                }
            }
            return txt;
        },

        _getComment: function _getComment(_element) {
            var txt = '';
            txt += _element.comment;
            return txt;
        },

        _getDimensions: function _getDimensions(_element) {
            var txt = '';
            if (_element.dimensions_length) {
                txt += _element.dimensions_length + _element.dimensions_unit;

                if (_element.dimensions_width) {
                    txt += ' x ';
                }
            }
            if (_element.dimensions_width) {
                txt += _element.dimensions_width + _element.dimensions_unit;

                if (_element.dimensions_height) {
                    txt += ' x ';
                }
            }
            if (_element.dimensions_height) {
                txt += _element.dimensions_height + _element.dimensions_unit;

                if (_element.dimensions_depth) {
                    txt += ' x ';
                }
            }
            if (_element.dimensions_depth) {
                txt += _element.dimensions_depth + _element.dimensions_unit;
            }
            return txt;
        },

        _getBaugruppeWithDetails: function _getBaugruppeWithDetails(_gesamtanlage, _type) {
            var tempText = '';

            if (_gesamtanlage.children) {

                var _ele = this._helperFindClassWithType(_gesamtanlage.children, 'Baugruppe', _type);

                if (_ele) {
                    tempText += '<span class="g">';
                    tempText += this._getType(_ele);
                    tempText += '</span>';

                    var _grundriss = this._helperFindType(_ele, 'Grundriss');

                    if (_grundriss) {
                        tempText += ', Grundriss: ';
                        tempText += '<span class="r">';
                        tempText += this._getFeature(_grundriss);
                        tempText += '</span>';
                    }

                    if (_ele.children.length > 0) {
                        tempText += ', mit: ';
                    }

                    for (var c = 0; c < _ele.children.length; c++) {
                        for (var d = 0; d < _ele.children[c]['class'].length; d++) {
                            if (_ele.children[c]['class'][d].label == 'Einzelbau') {

                                var _einzelbau = _ele.children[c];

                                tempText += '<br />';
                                tempText += '<span class="g">';
                                tempText += this._getType(_einzelbau);
                                tempText += '</span>';
                                tempText += ': ';
                                tempText += '<span class="r">';
                                tempText += this._getFeature(_einzelbau);
                                tempText += '</span>';

                                if (this._getComment(_einzelbau)) {
                                    tempText += ' (<span class="b">' + this._getComment(_einzelbau) + '</span>) ';
                                }

                                if (_einzelbau.children.length > 0) {
                                    tempText += ', mit: ';
                                }

                                tempText += this._getChildrenContentRekursiv(_einzelbau);
                            }
                        }
                    }
                }
            }

            return tempText;
        },

        _getChildrenContentRekursiv: function _getChildrenContentRekursiv(_element) {
            var tempText = '';

            for (var m = 0; m < _element.children.length; m++) {
                for (var n = 0; n < _element.children[m]['class'].length; n++) {

                    var _child = _element.children[m];

                    tempText += '<span class="r">';
                    tempText += this._getFeature(_child);
                    tempText += '</span>';

                    if (this._getDimensions(_child) || this._getComment(_child)) {

                        tempText += ' (';

                        if (this._getDimensions(_child)) {
                            tempText += '<span class="y">';
                            tempText += this._getDimensions(_child);
                            tempText += '</span>';

                            if (this._getComment(_child)) {
                                tempText += ', ';
                            }
                        }

                        if (this._getComment(_child)) {
                            tempText += '<span class="b">';
                            tempText += this._getComment(_child);
                            tempText += '</span>';
                        }

                        tempText += ')';
                    }

                    if (m < _element.children.length - 1) {
                        tempText += ', ';
                    }

                    //tempText += this._getChildrenContentRekursiv(_child);
                }
            }

            return tempText;
        },

        genAutoTextDescription: function genAutoTextDescription() {
            var tempText = '';

            var temp_sync_objref_list = this.get('sync_objref_list');

            var _gesamtanlage = this._helperFindClassWithType(temp_sync_objref_list, 'Baugruppe', 'Gesamtanlage');

            //Allgemein ---------------------------------------------------------
            var _bautyp = this._helperFindClass(_gesamtanlage, 'Bautyp');
            if (_bautyp) {

                tempText += '<span class="r">';
                tempText += this._getFeature(_bautyp);
                tempText += '</span>';

                if (this._getComment(_bautyp)) {
                    tempText += ' (<span class="b">' + this._getComment(_bautyp) + '</span>)';
                }

                tempText += '; ';
            }

            var _lage = this._helperFindClass(_gesamtanlage, 'Lage');
            if (_lage) {
                tempText += '<span class="g">';
                tempText += this._getType(_lage) + ' ';
                tempText += '</span>';

                if (this._getComment(_lage)) {
                    tempText += ' (<span class="b">' + this._getComment(_lage) + '</span>)';
                }
                tempText += '; ';
            }

            var _integritaet = this._helperFindClass(_gesamtanlage, 'Integrität');
            if (_integritaet) {
                tempText += '<span class="r">';
                tempText += this._getFeature(_integritaet);
                tempText += '</span>';
            }

            tempText += '<br /><br />';

            //Baugruppen (z.B. Hauptburg und Vorburg) -------------------------------------
            tempText += this._getBaugruppeWithDetails(_gesamtanlage, 'Hauptburg');
            tempText += '<br /><br />';
            tempText += this._getBaugruppeWithDetails(_gesamtanlage, 'Vorburg');

            return tempText;
        },

        actions: {
            add_short_descs: function add_short_descs() {
                if (typeof this.get('short_descs_class_select.label') != 'undefined') {
                    var _exist = false;

                    for (var i = 0; i < this.array_short_descs.length; i++) {
                        if (this.array_short_descs[i].label == this.get('short_descs_class_select.label')) {
                            _exist = true;
                        }
                    }

                    if (!_exist) {
                        this.array_short_descs.addObject({
                            label: this.get('short_descs_class_select.label'),
                            txt: '',
                            autotxt: ''
                        });
                    }
                }
            },
            remove_short_descs: function remove_short_descs(index) {
                this.array_short_descs.removeAt(index);
            },

            genAutoText: function genAutoText(index, _class) {
                var _autoTextString = '';

                if (_class == 'Bauentwicklung' || _class == 'Geschichte') {
                    for (var i = 0; i < this.sync_phaseref_list.length; i++) {
                        for (var k = 0; k < this.sync_phaseref_list[i].type.length; k++) {
                            var test = this.sync_phaseref_list[i].type[k];
                            console.log($(test)[0]['class'].label);
                            console.log(_class);

                            if (_class == 'Bauentwicklung' && $(test)[0]['class'].label == 'baulich' || _class == 'Geschichte' && $(test)[0]['class'].label == 'historisch') {
                                _autoTextString += this.sync_phaseref_list[i].begin;

                                if (this.sync_phaseref_list[i].end) {
                                    _autoTextString += ' bis ' + this.sync_phaseref_list[i].end;
                                }

                                _autoTextString += ': ' + this.sync_phaseref_list[i].comment + '.';
                                _autoTextString += '<br />\n';
                            }
                        }
                    }
                }

                if (_class == 'Beschreibung') {

                    _autoTextString = this.genAutoTextDescription();
                }

                console.log(_autoTextString);

                if (!_autoTextString) {
                    _autoTextString = 'Es konnte kein Autotext generiert werden. <b>Sorry :-(</b>';
                }

                var _temp_label = this.array_short_descs[index].label;
                var _temp_txt = this.array_short_descs[index].txt;
                this.array_short_descs.removeAt(index);
                this.array_short_descs.splice(index, 0, {
                    label: _temp_label,
                    txt: _temp_txt,
                    autotxt: _autoTextString
                });
            }
        }

    });
});