define('editor-burgendatenbank/routes/admin', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Route.extend({
        authmanager: _ember['default'].inject.service('auth-manager'),

        beforeModel: function beforeModel() {
            if (!this.get('authmanager').isAuthenticated() || !this.get('authmanager').userIsAdmin()) {
                this.transitionTo('index');
            }
        }
    });
});